import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { manage_event } from '../auth/services/permission.guard';
import { CompleteregComponent } from "./overview/completereg.component";

const routes: Routes = [
    {
        path: '',
        component: CompleteregComponent,
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class CompleteregRoutingModule { }