import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../auth/services/token.service';
const userUrl = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TwitterService {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  trackASpace(space_url: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post(userUrl + `/twitter/track-space`, { space_url }, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  saveATweet(tweet_url: any, name: any) {
    return this.http
      .post(userUrl + `/twitter/save-tweet`, { tweet_url, name }, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getSavedTweets(page: any, sort_key: any, direction: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .get(
        userUrl +
        `/twitter/users-tweets?page=${page}&sort_key=${sort_key}&direction=${direction}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  editTweetStatus(tweetId: any, status: any, name: any) {
    return this.http
      .patch(
        userUrl + `/twitter/edit-tweet-status/${tweetId}`,
        { status, name },
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getSolanaCoins() {
    return this.http
      .get(userUrl + `/solana/coin/role`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getallSolanaCoins() {
    return this.http
      .get(userUrl + `/solana/coin/role`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  listUserTwitterDrops(page: any, sort_key: string, direction: string) {
    return this.http
      .get(
        userUrl +
        `/twitter/drops/list-drop?page=${page}&sort_key=${sort_key}&direction=${direction}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  listSpaceTwitterDrops(page: any, sort_key: string, direction: string, channel: string) {
    return this.http
      .get(
        userUrl +
        `/twitter/drops/list-space-drop?page=${page}&sort_key=${sort_key}&direction=${direction}&channel=${channel}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  listPostDrops(page: any, sort_key: string, direction: string, channel: string) {
    return this.http
      .get(
        userUrl +
        `/user/medias/posts/drop?page=${page}&sort_key=${sort_key}&direction=${direction}&channel=${channel}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  listClaimedDrops(drop_id: any, page: any) {
    return this.http
      .get(
        userUrl + `/twitter/drops/drops-claimed/${drop_id}?page=${page}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  listClaimedPostDrops(drop_id: any, page: any) {
    return this.http
      .get(
        userUrl + `/user/medias/posts/drop/claimed/${drop_id}?page=${page}`,
        httpOptions
      )
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  createTwitterDrops(data: any) {
    return this.http
      .post(userUrl + `/twitter/drops/create-drop`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  createPostDrops(data: any) {
    return this.http
      .post(userUrl + `/user/medias/posts/drop`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  editTwitterDrops(drop_id: any, data: any) {
    return this.http
      .patch(userUrl + `/twitter/drops/edit-drop/${drop_id}`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  editPostDrops(drop_id: any, data: any) {
    return this.http
      .patch(userUrl + `/user/medias/posts/drop/${drop_id}`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getAllSavedTweets() {
    return this.http
      .get(userUrl + `/twitter/all-users-tweets`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getSpacetEvents(space_id: any) {
    return this.http
      .get(userUrl + `/twitter/tweet-events/${space_id}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getTweetEvents(id: any, page: any, sort_key: string, direction: string) {
    return this.http.get(
      userUrl +
      `/twitter/tweet-events/${id}?page=${page}&sort_key=${sort_key}&direction=${direction}`,
      httpOptions
    );
  }

  getDashboardStats() {
    return this.http
      .get(`${userUrl}/twitter/dashboard-stats`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getTwitterProfile(username: string) {
    return this.http.get(`${userUrl}/twitter/twitter-profile/${username}`, httpOptions).pipe()
  }

  getSpacesDetailsProfile(id: any) {
    return this.http.get(`${userUrl}/twitter/spaces-information/${id}`, httpOptions).pipe()
  }
  getSpacesDetailsCreator(id: any) {
    return this.http.get(`${userUrl}/twitter/creator/spaces-information/${id}`, httpOptions).pipe()
  }

  getAttendedSpaceData(id: any) {
    return this.http.get(`${userUrl}/twitter/attended-spaces/${id}`, httpOptions).pipe()
  }

  getcoHostedSpaceData(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/co-hosted/${id}page=${page}`, httpOptions).pipe()
  }

  getRegularSpeakersData(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/regular-speakers/${id}?page=${page}`, httpOptions).pipe()
  }

  getSpokeAtData(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/spoke-at/${id}?page=${page}`, httpOptions).pipe()
  }

  getSpokeAtDataCreator(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/creator/spoke-at/${id}?page=${page}`, httpOptions).pipe()
  }
  getAttendedDataCreator(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/creator/attended-spaces/${id}?page=${page}`, httpOptions).pipe()
  }

  getActiveSpacesData() {
    return this.http.get(`${userUrl}/twitter/active-spaces`, httpOptions).pipe()
  }

  getLatestSpacesData(id: any, page: any) {
    return this.http.get(`${userUrl}/twitter/all-spaces/${id}?page=${page}`, httpOptions).pipe()
  }
  // Funtion to get girrafe twitter users
  getTwitterlist() {
    return this.http.get(`${userUrl}/twitter/lists`, httpOptions).pipe();
  }
  addTwitterSegment(payload: any) {
    return this.http
      .post(userUrl + `/twitter/lists/store-twitter-segment`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  updateTwitterSegment(payload: any, id: any) {
    return this.http
      .patch(userUrl + `/twitter/lists/edit-twitter-segment/${id}`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  myTwitterLists() {
    return this.http.get(`${userUrl}/twitter/lists/twitter-segments`, httpOptions).pipe();
  }
  refreshTwitterSegment(id: any) {
    return this.http.get(`${userUrl}/twitter/lists/refresh-twitter-segment/${id}`, httpOptions).pipe();
  }

  linkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${userUrl}/user/link-twitter`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}
