import { SettingsService } from './../../../../modules/account/settings.service';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { AuthService, UserType } from 'src/app/modules/auth';
import { LayoutService } from '../../core/layout.service';
import { TranslationService } from 'src/app/modules/i18n';
import { Router } from '@angular/router';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showModal: boolean = false;
  public iframeSrc: string = '';
  custodial_wallet = '';
  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  user: any;
  details: any;
  // user$: Observable<UserType>;

  @ViewChild(ModalPopupComponent) modalPopup: ModalPopupComponent;

  // @HostBinding('class')
  // class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;

  @HostBinding('attr.data-kt-menu')
  dataKtMenu = 'true';

  user$: Observable<UserType>;
  private unsubscribe: Subscription[] = [];
  team: any = [];

  constructor(
    private layout: LayoutService,
    // private settings: SettingsService,
    private auth: AuthService,
    private translationService: TranslationService,
    private settings: SettingsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user$ = this.settings.currentUserSubject.asObservable();
    this.headerLeft = this.layout.getProp('header.left') as string;
    const a = JSON.parse(localStorage.getItem('userdetails'))
    this.custodial_wallet = a.custodial_wallet
    this.getuserdetails();

    this.getTeams();
    // this.user$ = this.auth.currentUserSubject.asObservable();
  }

  handleModalToggle() {
    this.showModal = !this.showModal;

    // this.iframeSrc = `https://onramp.mirrorworld.fun/?fiat_amount=0&network=solana&crypto_currency=sol&wallet_address=[${this.custodial_wallet}+_address]`
    // this.iframeSrc = `https://onramp.mirrorworld.fun/?api_key=mw_AD3fNZ8vygPjG5NGsWfdYlYYgXqOogqNnx2&network=solana&crypto_currency=sol&wallet_address=${this.custodial_wallet}&fiat_currency=USD`
    this.iframeSrc = `https://onramp.mirrorworld.fun/?api_key=mw_AD3fNZ8vygPjG5NGsWfdYlYYgXqOogqNnx2&network=solana&crypto_currency=sol&wallet_address=${this.custodial_wallet}`

  }

  getuserdetails() {
    this.isLoading$$.next(true);
    this.details = JSON.parse(localStorage.getItem('userdetails') || '{}');
    this.user = this.details?.name;
    // console.log(this.user);
    this.settings.getUserDetails().subscribe({
      next: (res) => { },
      complete: () => {
        this.details = JSON.parse(localStorage.getItem('userdetails') || '{}');
        this.user = this.details?.name;
        this.isLoading$$.next(false);
      }
    })
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }
  getTeams() {
    // team_id: 653
    // team_name: "jonathan solagbade
    this.settings.getMyTeam().subscribe({
      next: (res: any) => {
        this.team = res.data;
      },
    });
  }
  switchTeam(team_id: any) {
    this.settings.switchTeam(team_id).subscribe({
      next: (res) => {
        // //console.log(res);
      },
      complete: () => {
        this.router.navigateByUrl('/dashboard');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
