<div *ngIf="displaydata"
  class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed mt-15">
  <!--begin::Content-->
  <div class="d-flex flex-center flex-column flex-column-fluid pb-lg-20">
    <!--begin::Content body-->
    <div class="mx-auto">
      <div class="container">
        <!-- <div class="d-flex py-5 align-items-center justify-content-center">
          <img class="img-fluid" src="assets/imgs/logo-socialpay.png" alt="socialpay-logo" />
          <div class="d-block textlogo">
            <h1>SOCIAL</h1>
            <h1>PAY</h1>
          </div>
        </div> -->

        <div class="row mx-auto">
          <div class="col-xxl-12 mx-auto my-auto">
            <div>
              <ngx-twitter-tweet [tweetID]="transactions?.twitter_transaction.tweet_id"
                [options]="{ size: 'large', text: 'Tweet from Twitter' }" (onLoad)="onLoad($event)">
              </ngx-twitter-tweet>
            </div>
            <div class="card " *ngIf="!showActivated">
              <!-- <div class="card-header text-center align-items-center justify-content-center">
                <h4 class="card-title mb-4">Transaction Details</h4>
              </div> -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex align-items-center  "
                      *ngIf="transactions?.transfer_transaction?.status === 'completed'">
                      <div class="color me-5"></div>
                      <h2 class="mb-0">Confirmed on the blockchain
                      </h2>
                    </div>

                    <div class="d-flex align-items-center  "
                      *ngIf="transactions?.transfer_transaction?.status !== 'completed'">
                      <div class="colorp me-5"></div>
                      <h3 class="mb-0">Pending confirmation on blockchain
                      </h3>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div class="card mt-7">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="ms-0 me-5">
                    <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3"
                          d="M21 22H12C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2H21C21.6 2 22 2.4 22 3V21C22 21.6 21.6 22 21 22ZM15.4 17L19.7 12.7C20.1 12.3 20.1 11.7 19.7 11.3L15.4 7V17Z"
                          fill="currentColor" />
                        <path d="M15.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H15.4V11Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                  <h3>Sent</h3>
                  <div class="ms-auto">
                    <h3>{{
                      transactions?.twitter_transaction?.amount }} {{ transactions?.twitter_transaction?.coin_symbol }}
                    </h3>
                  </div>
                </div>
                <div class="border-gray-300 border-bottom border-bottom-dashed my-5"></div>
                <div class="d-flex align-items-center">
                  <div class="ms-0 me-5">
                    <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="18" height="18" viewBox="0 0 18 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3"
                          d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                          fill="currentColor" />
                        <path
                          d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                          fill="currentColor" />
                        <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                  <div class="fixed-width">
                    <h4 class="text-muted"
                      *ngIf="transactions?.twitter_transaction?.receiver_twitter_username !== null">to @{{
                      transactions?.twitter_transaction?.receiver_twitter_username }} </h4>
                    <h4 class="text-muted"
                      *ngIf="transactions?.twitter_transaction?.receiver_twitter_username === null">to {{
                      transactions?.twitter_transaction?.receiver_twitter_user_id }} </h4>
                  </div>

                  <!-- <div class="ms-auto">
                    <h4 class="text-muted">$1.17</h4>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card mt-7" *ngIf="showActivated">
              <div class="card-body ">
                <div class="fixed-width">
                  <h2 class="mb-3">Is that you, @{{ transactions?.twitter_transaction?.receiver_twitter_username }}?
                  </h2>
                  <p>@{{ transactions.sender }} sent some coins to your SocialConnector wallet. You can claim your coin
                    by
                    completing your account setup.</p>
                  <br>
                  <p>
                    To get started, complete your account setup and link your twitter account.
                  </p>
                  <div class="text-center mt-5">
                    <a href="https://app.socialconnector.io/auth/registration/{{transactions.referral_id}}"
                      target="_blank" class="btn btn-primary btn-block">ACTIVATE YOUR WALLET</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="card mt-7" *ngIf="transactions?.transfer_transaction?.status === 'completed'">
              <div class="card-header">
                <h3 class="card-title">
                  Transaction Details
                </h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h4 class="mb-2">Transaction Time</h4>
                    <h4 class="text-muted">
                      {{ transactions?.twitter_transaction?.created_at| date:'medium' }} </h4>
                    <br>
                    <!-- <h4 class="mb-2">Transaction Fee</h4>
                    <h4 class="text-muted">0.000005 SOL</h4>
                    <br> -->
                    <h4 class="mb-2">Signature</h4>
                    <h5 class="text-muted fixed-width">
                      {{ transactions?.transfer_transaction?.signature }}
                    </h5>
                  </div>
                </div>
                <div class="text-center mt-7" *ngIf="transactions?.transfer_transaction?.status === 'completed'">
                  <a href="https://solscan.io/tx/{{transactions?.transfer_transaction?.signature}}" target="_blank"
                    class="btn btn-primary btn-block">VIEW ON SOLSCAN</a>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Content body-->
  </div>
  <!--end::Content-->

  <!-- begin::Footer -->
  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="row">
      <div class="col-12 text-center">
        <div class="d-block">
          <p class="mb-1">
            <span class="text-muted fw-bold me-2">{{ currentDateStr }} &copy;</span>
            <a class="text-gray-800 text-hover-primary cursor-pointer">
              Social Connector
            </a>
          </p>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="d-block">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="https://docs.socialconnector.io/welcome-to-social-connector" target="_blank"
                class="menu-link pe-0 pe-2 cursor-pointer">
                Doc
              </a>
            </li>
            <li class="list-inline-item">
              <a href="mailto:team@digitalmarketing.org" target="_blank" class="menu-link pe-0 pe-2 cursor-pointer">
                Contact
              </a>
            </li>
            <li class="list-inline-item">
              <a href="https://discord.gg/YERX2dNAGS" target="_blank" class="menu-link pe-0 pe-2 cursor-pointer">
                Support
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Footer -->
</div>
