import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { DrawerComponent } from '../../../kt/components';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  userData: any;
  twiterLinked: boolean;
  discordLinked: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.routingChanges();
    this.userData = JSON.parse(localStorage.getItem('userdetails') || "{}");
    if(this.userData['twitter_data'] === '') {
      this.twiterLinked = false;
    } else {
      this.twiterLinked = true;
    }
    if(this.userData['discord_data'] === '') {
      this.discordLinked = false;
    } else {
      this.discordLinked = true;
    }
  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // DrawerComponent.hideAll();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
