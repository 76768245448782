<div class="modal" [ngClass]="{'active': showModal}">
  <div class="modal-content">
    <span class="close-button" (click)="closeModal()">&times;</span>

    <ng-container *ngIf="iframeSrc; else loading">
      <iframe [src]="safeIframeSrc()" width="100%" height="400"></iframe>
    </ng-container>

    <ng-template #loading>
      Loading...
    </ng-template>

  </div>
</div>