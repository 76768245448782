import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FirsttimeIntroModalComponent } from 'src/app/modules/auth/components/firsttime-intro-modal/firsttime-intro-modal.component';
import { LayoutService } from '../../core/layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerContainerCssClasses: string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  constructor(private layout: LayoutService,
    private modalService: NgbModal,
    config: NgbModalConfig, 
    ) {}

  ngOnInit(): void {
    this.footerContainerCssClasses =
      this.layout.getStringCSSClasses('footerContainer');
  }
  watchTutorialModal() {
    this.modalService.open(FirsttimeIntroModalComponent, { size: 'xl', centered: false, backdrop: 'static' }).result;
  }
}
