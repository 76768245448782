<div class="container mb-15">
  <div class="row mt-15">
    <div class="col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="text-end pt-5 pb-2 pe-8">
          <a style="cursor: pointer" (click)="close()"><i class="fa fa-times text-danger" aria-hidden="true"></i>
            Cancel</a>
        </div>
        <div class="card-body pt-0">
          <div class="border">
            <div class="row align-items-center px-10 pt-7">
              <div class="col-md-6">
                <h3>ORDER SUMMARY</h3>
                <div class="w-50px">
                  <hr />
                </div>
              </div>
              <div class="col-md-6 text-sm-center text-md-end">
                <h3 class="text-primary">
                  {{ total }}
                  {{ initResp?.payment_transaction.cryptocurrency_type }}
                </h3>
              </div>
            </div>
            <div class="p-5">
              <div class="mt-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card card-bordered">
                      <div class="p-5 text-center">
                        <p class="mb-0">Transaction Reference</p>
                        <h3>
                          {{
                          initResp?.payment_transaction.transaction_reference
                          }}
                        </h3>

                        <p class="mb-0">Transaction Memo</p>
                        <h3>
                          {{ initResp?.payment_transaction.transaction_memo }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center align-items-centerr " *ngIf="!hidePay && !isExpired">
                <div class="col-md-6">
                  <div class="my-15">
                    <div class="text-center mb-5">
                      <p>
                        <span class="indicator-progress" [style.display]="'block'">
                          {{ payInfo }} ...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </p>
                      <h3>Payment expires in :</h3>
                    </div>
                    <div class="text-center" *ngIf="(isLoading$$ | async) === false">
                      <h1>{{ daysToDday }} : {{ hoursToDday }}: {{ minutesToDday }} : {{ secondsToDday }}</h1>
                    </div>
                    <!-- <div class="time d-flex align-items-center justify-content-center"
                      *ngIf="(isLoading$$ | async) === false">
                      <div class="card bg-light mx-md-3">
                        <div class="p-5 text-center">
                          <h1>{{ daysToDday }}</h1>
                          <p>DAYS</p>
                        </div>
                      </div>
                      <div class="card bg-light mx-md-3 mx-sm-1">
                        <div class="p-5 text-center">
                          <h1>{{ hoursToDday }}</h1>
                          <p>HOURS</p>
                        </div>
                      </div>
                      <div class="card bg-light mx-md-3 mx-sm-1">
                        <div class="p-5 text-center">
                          <h1>{{ minutesToDday }}</h1>
                          <p>MINUTES</p>
                        </div>
                      </div>
                      <div class="card bg-light mx-md-3 mx-sm-1">
                        <div class="p-5 text-center">
                          <h1>{{ secondsToDday }}</h1>
                          <p>SECONDS</p>
                        </div>
                      </div>
                    </div> -->
                  </div>

                </div>
                <div class="col-md-6 text-center">
                  <ngx-qrcode-styling [config]="config"></ngx-qrcode-styling>
                </div>

              </div>


            </div>

            <div class="my-5 text-center" *ngIf="!hidePay && !isExpired">
              <div ngbDropdown container="body" class="d-inline-block" *ngIf="(connected$ | async) === false">
                <button type="button"
                  class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary py-3 px-5 mb-3"
                  id="dropdownBasic1" ngbDropdownToggle>
                  <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
                </button>
                <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3 dropdown-menu-dark">
                  <li *ngFor="let wallet of wallets$ | async">
                    <button type="button" ngbDropdownItem class="dropdown-item2 d-flex align-items-center mb-3"
                      (click)="onSelectWallet(wallet.adapter.name)">
                      <span>
                        <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3"
                          alt="" /></span>{{
                      wallet.adapter.name }}
                    </button>
                  </li>
                </ul>
              </div>
              <button class="btn  btn-outline btn-outline-dashed btn-primary btn-lg btn-hover-scale ms-5 mb-3"
                (click)="openModal(content)" [disabled]="this.showLoad">Pay With
                SocialConnector
                <span *ngIf="this.showLoad" class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </button>

              <button class="btn btn-primary btn-lg btn-block btn-hover-scale mx-5 mb-3" type="button"
                (click)="payFunction()" *ngIf="connected$ | async">
                <ng-container *ngIf="isLoading$$ | async">
                  <span class="indicator-progress" [style.display]="'block'">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </ng-container>
                <ng-container *ngIf="(isLoading$$ | async) === false">
                  <span class="indicator-label">
                    PAY {{ total }} {{ initResp?.payment_transaction.cryptocurrency_type }}
                  </span>
                </ng-container>
              </button>
            </div>
            <div class="my-5 text-center" *ngIf="hidePay">
              <div class="d-flex align-items-center justify-content-center">
                <div class="me-5">
                  <span class="svg-icon svg-icon-success svg-icon-2hx">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="currentColor" />
                    </svg>
                  </span>
                </div>
                <div class="d-block">
                  <h4>Transaction Completed</h4>
                  <p class="mb-0">
                    View transaction on
                    <a href="https://solscan.io/tx/{{ paidSignature }}" target="_blank">solscan</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="my-5 text-center" *ngIf="isExpired && !hidePay">
              <div class="d-flex align-items-center justify-content-center">
                <div class="me-5">
                  <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                        fill="currentColor" />
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                        fill="currentColor" />
                    </svg>
                  </span>
                </div>
                <div class="d-block">
                  <h4>Transaction expired</h4>
                  <h4>
                    Back to
                    <a [routerLink]="['/checkout', page_link]" routerLinkActive="router-link-active">
                      Checkout</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <form name="form" #f="ngForm" (ngSubmit)="f.valid && onSubmit()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Pay with SocialConnector</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <h4 class="mb-5"> Enter your SocialConnector login details to pay with your SocialConnector wallet</h4>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label for="" class="form-label">Email</label>
            <input type="email" class="form-control" class="form-control" [(ngModel)]="model.email" name="email"
              placeholder="Social connector email" #email="ngModel">
          </div>
          <div class="form-group mb-3">
            <label for="" class="form-label">Password</label>
            <input type="password" class="form-control" [(ngModel)]="model.password" name="password"
              placeholder="Social connector password" #password="ngModel">
          </div>
          <p>Don't have an account?, <a href="https://app.socialconnector.io/auth/registration" target="_blank">Click
              here to register on Social Connector</a>
          </p>

        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Cancel</button>
      <button class="btn btn-primary" type="submit" [disabled]="f.invalid">Pay Now</button>
    </div>
  </form>
</ng-template>