<div class="container mb-15">
  <div class="d-flex pb-5 align-items-center justify-content-center">
    <img class="img-fluid" src="assets/imgs/logo-socialpay.png" alt="socialpay-logo" />
    <div class="d-block textlogo ms-5">
      <h1>SOCIAL</h1>
      <h1 class="mb-0">PAY</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="border">
            <div class="p-5">
              <h3>ORDER SUMMARY</h3>
              <div class="w-50px">
                <hr />
              </div>
              <div class="mt-15">
                <div class="card">
                  <div class="p-5">
                    <div class="row">
                      <div class="col-md-8">
                        <h4>
                          {{ initResp?.payment_page.page_name }}
                        </h4>
                        <!-- <br />
                        <p class="mb-1">
                          REF: GdEayBchh8wX8JGz6vRPfH6SNZetGhn1YKwEircnvFZQ
                        </p>
                        <p class="mb-0">MEMO: TMEM_e401qmwd</p> -->
                      </div>
                      <div class="col-md-4 text-md-end">
                        <h4>
                          {{ total }}
                          {{ initResp?.payment_page.cryptocurrency_type }}
                        </h4>
                      </div>
                    </div>
                    <br />
                    <p class="mb-0" [innerHTML]="initResp?.payment_page.description"></p>
                  </div>
                </div>
              </div>
              <div class="mt-7">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card card-bordered">
                      <div class="p-5 text-center">
                        <p class="mb-0">Transaction Reference</p>
                        <h3>
                          {{
                          initResp?.payment_transaction.transaction_reference
                          }}
                        </h3>

                        <p class="mb-0">Transaction Memo</p>
                        <h3>
                          {{ initResp?.payment_transaction.transaction_memo }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5 text-center" *ngIf="
                      initResp?.payment_transaction
                        .recurring_payment_config_id === null
                    ">
                    <ngx-qrcode-styling [config]="config"></ngx-qrcode-styling>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-7">
                <div class="card card-bordered">
                  <div class="p-5">
                    <p class="mb-0">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Unde, adipisci blanditiis! Harum, nam! Qui, sapiente. Quod
                      tempore asperiores deserunt necessitatibus?
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="my-15" *ngIf="!hidePay && !isExpired">
              <div class="text-center mb-5">
                <p>
                  <span class="indicator-progress" [style.display]="'block'">
                    {{ payInfo }} ...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </p>
                <h3>Payment expires in :</h3>
              </div>
              <div class="time d-flex align-items-center justify-content-center"
                *ngIf="(isLoading$$ | async) === false">
                <div class="card bg-light mx-md-3">
                  <div class="p-5 text-center">
                    <h1>{{ daysToDday }}</h1>
                    <p>DAYS</p>
                  </div>
                </div>
                <div class="card bg-light mx-md-3 mx-sm-1">
                  <div class="p-5 text-center">
                    <h1>{{ hoursToDday }}</h1>
                    <p>HOURS</p>
                  </div>
                </div>
                <div class="card bg-light mx-md-3 mx-sm-1">
                  <div class="p-5 text-center">
                    <h1>{{ minutesToDday }}</h1>
                    <p>MINUTES</p>
                  </div>
                </div>
                <div class="card bg-light mx-md-3 mx-sm-1">
                  <div class="p-5 text-center">
                    <h1>{{ secondsToDday }}</h1>
                    <p>SECONDS</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <hr class="mb-0" /> -->
            <!-- <div class="bg-light p-5 text-center">
              <h4>WEDNESDAY</h4>
              <h5>JANUARY 25, 2022 | 11:37 PM</h5>
            </div> -->
          </div>

          <div class="my-5 text-center" *ngIf="!hidePay && !isExpired">
            <div ngbDropdown class="d-inline-block" *ngIf="
                (connected$ | async) === false &&
                initResp?.payment_transaction.recurring_payment_config_id ===
                  null
              ">
              <button type="button"
                class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary py-3 px-5 mb-3"
                id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
              </button>
              <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3 dropdown-menu-dark">
                <li *ngFor="let wallet of wallets$ | async">
                  <button type="button" ngbDropdownItem class="dropdown-item2 d-flex align-items-center"
                    (click)="onSelectWallet(wallet.adapter.name)">
                    <span>
                      <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3" alt="" /></span>{{
                    wallet.adapter.name }}
                  </button>
                </li>
              </ul>
            </div>
            <button class="btn btn-outline btn-outline-dashed btn-primary btn-lg btn-hover-scale ms-5 mb-3"
              (click)="openModal(content)" [disabled]="this.showLoad">
              Pay With SocialConnector
              <span *ngIf="this.showLoad" class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </button>

            <button class="btn btn-primary btn-lg btn-block btn-hover-scale mx-5 mb-3" type="button"
              (click)="payFunction()" *ngIf="initResp?.payment_transaction.recurring_payment_config_id ===
              null && connected$ | async">
              <ng-container *ngIf="isLoading$$ | async">
                <span class="indicator-progress" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="(isLoading$$ | async) === false">
                <span class="indicator-label">PAY</span>
              </ng-container>
            </button>
          </div>
          <div class="my-5 text-center" *ngIf="hidePay">
            <div class="d-flex align-items-center justify-content-center">
              <div class="me-5">
                <span class="svg-icon svg-icon-success svg-icon-2hx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                    <path
                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                      fill="currentColor" />
                  </svg>
                </span>
              </div>
              <div class="d-block">
                <h4>Transaction Completed</h4>
                <p class="mb-0">
                  View transaction on
                  <a href="https://solscan.io/tx/{{ paidSignature }}" target="_blank">solscan</a>
                </p>
              </div>
            </div>
          </div>
          <div class="my-5 text-center" *ngIf="isExpired && !hidePay">
            <div class="d-flex align-items-center justify-content-center">
              <div class="me-5">
                <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                    <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                      fill="currentColor" />
                    <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                      fill="currentColor" />
                  </svg>
                </span>
              </div>
              <div class="d-block">
                <h4>Transaction expired</h4>
                <h4>
                  Back to
                  <a [routerLink]="['/checkout', page_link]" routerLinkActive="router-link-active">
                    Checkout</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <form name="form" #f="ngForm" (ngSubmit)="f.valid && onSubmit()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Pay with SocialConnector
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <h4 class="mb-5">
        Enter your SocialConnector login details to pay with your
        SocialConnector wallet
      </h4>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label for="" class="form-label">Email</label>
            <input type="email" class="form-control" class="form-control" [(ngModel)]="model.email" name="email"
              placeholder="Social connector email" #email="ngModel" />
          </div>
          <div class="form-group mb-3">
            <label for="" class="form-label">Password</label>
            <input type="password" class="form-control" [(ngModel)]="model.password" name="password"
              placeholder="Social connector password" #password="ngModel" />
          </div>
          <p>
            Don't have an account?,
            <a href="https://app.socialconnector.io/auth/registration" target="_blank">Click here to register on Social
              Connector</a>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
        Cancel
      </button>
      <button class="btn btn-primary" type="submit" [disabled]="f.invalid">
        Pay Now
      </button>
    </div>
  </form>
</ng-template>