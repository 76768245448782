<div class="
    d-flex
    flex-column flex-column-fluid
    bgi-position-y-bottom
    position-x-center
    bgi-no-repeat bgi-size-contain bgi-attachment-fixed
  ">
  <!--begin::Content-->
  <div class=" d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
    <a routerLink="/" class="mb-12">
      <!-- <img alt="Logo" src="./assets/media/logos/default-dark2.png" class="h-45px" /> -->
      <img src="assets/imgs/logo.png" class="mb-5 h-45px" alt="SocialConnector logo" />
    </a>

    <!--begin::Content body  -->
    <div class="w-lg-900px bg-body rounded shadow-sm p-5 p-lg-7 mx-auto">
      <span class="badge badge-light-success mb-5">Last Revised: 22/9/2023</span>
      <h2 class="mb-4">Social Connector Fees</h2>

      <div class="table-responsive">
        <table class="table table-rounded table-striped border gy-7 gs-7">
          <thead>
            <tr class="fw-bold fs-6 text-gray-800">
              <th>Description</th>
              <th>Charge Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NFT Loader</td>
              <td>5 $GARY per day</td>
            </tr>
            <tr>
              <td>Creating Collectibles</td>
              <td>0.0219812 SOL</td>
            </tr>
            <tr>
              <td>Creating Collection</td>
              <td>0.0219812 SOL</td>
            </tr>
            <tr>
              <td>SocialPay Charges</td>
              <td>1.5% of total fee</td>
            </tr>
            <tr>
              <td>Marketing Credit</td>
              <td>1 $GARY per 10 Marketing Unit</td>
            </tr>
            <tr>
              <td>Minting NFT</td>
              <td>0.02281444 SOL + 1.5% of fee charged</td>
            </tr>
            <tr>
              <td>Stripe</td>
              <td>$0.30 + 4.4% of total fee</td>
            </tr>
            <tr>
              <td>Naming Collectibles</td>
              <td>100 $Gary + 1.5% charges per collectible</td>
            </tr>
            <tr>
              <td>Monetization Activation Fee</td>
              <td>0.03193224 SOL</td>
            </tr>
            <tr>
              <td>Monetization (Support and Stop Supporting)</td>
              <td>1.5% of total fee</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--end::Content body-->
  </div>
  <!--end::Content-->

  <!-- begin::Footer -->
  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="d-flex align-items-center fw-bold fs-6">
      <a href="https://social-connector.gitbook.io/social-connector/" target="_blank" class="text-muted text-hover-primary px-2 cursor-pointer">How To Use Social Connector</a>
      <a href="https://social-connector.gitbook.io/social-connector/faq/how-to-buy-usdgary-coin" target="_blank" class="text-muted text-hover-primary px-2 cursor-pointer">Buy $GARY</a>
      <a href="/terms" class="text-muted text-hover-primary px-2 cursor-pointer">Terms & Condition</a>
    </div>
  </div>
  <!-- end::Footer -->
</div>