import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class update_coin_data_for_creators implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'update_coin_data_for_creators';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class list_coin_data_for_creators implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'list_coin_data_for_creators';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_creator_supporters implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_creator_supporters';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class export_creator_supporters implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_creator_supporters';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_creator_trades implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_creator_trades';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_creator_rewards implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_creator_rewards';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_discord_roles implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_discord_roles';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_discord_roles implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_discord_roles';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_discord_bots implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_discord_bots';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_discord_bots implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_discord_bots';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_clubhouse_bots implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_clubhouse_bots';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_clubhouse_bots implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_clubhouse_bots';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_searchie_config implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_searchie_config';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_searchie_config implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_searchie_config';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_searchie_info implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_searchie_info';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_searchie_info implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_searchie_info';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_monitored_tweets implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_monitored_tweets';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_monitored_tweets implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_monitored_tweets';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class track_space implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'track_space';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_twitter_drops implements CanActivate {
  constructor(private token: TokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_twitter_drops';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_twitter_drops implements CanActivate {
  constructor(private token: TokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_twitter_drops';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_events implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_events';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_events implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_events';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_event_logs implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_event_logs';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_event_access implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_event_access';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_event_settings implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_event_settings';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_event_settings implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_event_settings';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_team implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_team';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class claim_clubs_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'claim_clubs_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_comparison_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_comparison_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_clubhouse_profile_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_clubhouse_profile_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_track_room_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_track_room_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_track_room_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_track_room_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_super_fans_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_super_fans_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_top_rooms_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_top_rooms_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_room_stats_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_room_stats_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_clubs_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_clubs_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_my_clubs_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_my_clubs_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class manage_my_clubs_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_my_clubs_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_weekly_reports_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_weekly_reports_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_club_details_clubhouse implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_club_details_clubhouse';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_form_config implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_form_config';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class view_form_response implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_form_response';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}



@Injectable({
  providedIn: 'root',
})
export class view_segment implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'view_segment';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}


@Injectable({
  providedIn: 'root',
})
export class manage_segment implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_segment';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}


@Injectable({
  providedIn: 'root',
})
export class manage_badge implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_badge';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}



@Injectable({
  providedIn: 'root',
})
export class migrate_nft implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'migrate_nft';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}


@Injectable({
  providedIn: 'root',
})
export class manage_segment_airdrop implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_segment_airdrop';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}



@Injectable({
  providedIn: 'root',
})
export class manage_Collectible implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_collectible';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}


@Injectable({
  providedIn: 'root',
})
export class manage_event implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_event';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}

// manage_segment_airdrop



// Manage supporter

@Injectable({
  providedIn: 'root',
})
export class manage_supporter implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_supporter';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}



// Manage Tower

@Injectable({
  providedIn: 'root',
})
export class manage_tower implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'manage_tower';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}



@Injectable({
  providedIn: 'root',
})
export class post_airdrop implements CanActivate {
  constructor(
    private authService: AuthService,
    private token: TokenService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const a = this.token.getUserFromStorage();
    const first = a.find((obj: any) => {
      return obj.name === 'post_airdrop';
    });
    if (first) {
      return true;
    }
    Swal.fire('', 'You do not have access to this feature', 'info');
    this.router.navigateByUrl('/dashboard');
    return false;
  }

}
