// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   name: 'development',
//   base_api: 'https://gatewaystaging.socialconnector.io/api/',
//   social_pay_baseurl: 'https://gatewaystaging.socialconnector.io/api/v2/social-pay',
//   solana_api:
//     'https://rpc.ankr.com/solana/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
//   solana_api_ws:
//     'wss://rpc.ankr.com/solana/ws/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
//   social_pay_gateway: 'https://gateway.scpay.socialconnector.io/',
//   network: 'mainnet',
//   production: false,
//   staging: true,
//   appVersion: '2.0.0',
//   roomTrackingApi: 'https://gatewaystaging.socialconnector.io/api/v2/clubhouse/rooms/track/',
//   USERDATA_KEY: 'authfP649fc9a5f55',
//   isMockEnabled: false,
//   apiUrl: 'https://gatewaystaging.socialconnector.io/api/v2',
//   appThemeName: 'Social Connector',
//   appPurchaseUrl: 'https://1.envato.market/EA4JP',
//   appHTMLIntegration:
//     'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
//   appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
//   appPreviewAngularUrl:
//     'https://preview.keenthemes.com/metronic8/angular/demo1',
//   appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
//   appPreviewChangelogUrl:
//     'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const environment = {
  name: 'development',
  creator_profile: 'https://exdev.socialconnector.io/',
  token: 'CWB4hSWCoZrzurtMj9CLkSYfV5dpYgcxZ1UKBETxcGbV',
  base_api: 'https://gatewaytesting.socialconnector.io/api/v2',
  // 'https://testingdev.socialconnector.io/api/',
  social_pay_baseurl: 'https://gatewaytesting.socialconnector.io/api/v2/social-pay',
  // 'https://testing.socialpay.socialconnector.io/api/v1',
  solana_api: 'https://rpc.ankr.com/solana/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
  solana_api_ws: 'wss://rpc.ankr.com/solana/ws/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
  social_pay_gateway: 'https://gateway.scpay.socialconnector.io/',
  network: 'mainnet',
  production: false,
  roomTrackingApi: 'https://gatewaytesting.socialconnector.io/api/v2/clubhouse/rooms/track/',
  appVersion: '2.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://gatewaytesting.socialconnector.io/api/v2',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration:
    'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl:
    'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl:
    'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  appDemos: {
    demo1: {
      title: 'Social Connector',
      description: 'All-in-one Social tracking app',
      published: true,
      thumbnail: './assets/media/demos/demo1.png',
    },

    demo2: {
      title: 'Demo 2',
      description: 'SaaS Application',
      published: true,
      thumbnail: './assets/media/demos/demo2.png',
    },

    demo3: {
      title: 'Demo 3',
      description: 'New Trend',
      published: true,
      thumbnail: './assets/media/demos/demo3.png',
    },

    demo4: {
      title: 'Demo 4',
      description: 'Intranet Application',
      published: true,
      thumbnail: './assets/media/demos/demo4.png',
    },

    demo5: {
      title: 'Demo 5',
      description: 'Support Forum',
      published: false,
      thumbnail: './assets/media/demos/demo5.png',
    },

    demo6: {
      title: 'Demo 6',
      description: 'Admin Backend',
      published: true,
      thumbnail: './assets/media/demos/demo6.png',
    },

    demo7: {
      title: 'Demo 7',
      description: 'CRM Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo7.png',
    },

    demo8: {
      title: 'Demo 8',
      description: 'Core Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo8.png',
    },

    demo9: {
      title: 'Demo 9',
      description: 'Fancy Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo9.png',
    },

    demo10: {
      title: 'Demo 10',
      description: 'Modern Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo10.png',
    },

    demo11: {
      title: 'Demo 11',
      description: 'Light Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo11.png',
    },

    demo12: {
      title: 'Demo 12',
      description: 'Reporting System',
      published: false,
      thumbnail: './assets/media/demos/demo12.png',
    },

    demo13: {
      title: 'Demo 13',
      description: 'Classic Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo13.png',
    },

    demo14: {
      title: 'Demo 14',
      description: 'Creative Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo14.png',
    },

    demo15: {
      title: 'Demo 15',
      description: 'Minimalistic Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo15.png',
    },

    demo16: {
      title: 'Demo 16',
      description: 'Modern Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo16.png',
    },

    demo17: {
      title: 'Demo 17',
      description: 'Backend System',
      published: false,
      thumbnail: './assets/media/demos/demo17.png',
    },

    demo18: {
      title: 'Demo 18',
      description: 'System Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo18.png',
    },

    demo19: {
      title: 'Demo 19',
      description: 'Light Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo19.png',
    },

    demo20: {
      title: 'Demo 20',
      description: 'Monochrome Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo20.png',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
