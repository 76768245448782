import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { SettingsService } from "src/app/modules/account/settings.service";
import { InstagramService } from "src/app/modules/instagram/service/instagram.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-firsttime-intro-modal',
  templateUrl: './firsttime-intro-modal.component.html',
  styleUrls: ['./firsttime-intro-modal.component.scss']
})
export class FirsttimeIntroModalComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  details: any;
  userDetails: any;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  constructor(
    private authService: AuthService,
    private instagramService: InstagramService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    config: NgbModalConfig,
  ) { }

  ngOnInit(): void {
    this.details = JSON.parse(localStorage.getItem('userdetails') || '{}');
    this.userDetails = this.details?.name;
  }  
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  // Function to handle if video is watched or skip
  watchVideo() {
      this.instagramService.watchVideo().subscribe({
        next: (res: any) => {
          this.isLoading$$.next(true);
          this.settingsService.getUserDetails().subscribe({
            next: (res: any) => { 
              this.details = JSON.parse(localStorage.getItem('userdetails') || '{}');
              this.userDetails = this.details?.name;
              this.isLoading$$.next(false);
              this.close();
            },
            complete: () => {
              this.details = JSON.parse(localStorage.getItem('userdetails') || '{}');
              this.userDetails = this.details?.name;
              this.isLoading$$.next(false);
              this.close();
            }
          })
        },
        complete: () => { },
        error: (err: any) => {
        },
      });
  }
  close() {
    this.modalService.dismissAll()
  }

}
