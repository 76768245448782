import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { POWService } from '../../modules/creator/pow.service';
import html2canvas from 'html2canvas'
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  @ViewChild('cardToDownload') cardToDownload: ElementRef;
  id: string;
  powData: any;
  date: any;

  constructor(
    private route: ActivatedRoute,
    private powService: POWService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    // const cardElement = this.cardToDownload.nativeElement;
    // cardElement.style.display = 'none';
    this.getPOW()
  }

  getPOW() {
    this.powService.getSinglePOW(this.id).subscribe({
      next: (res: any) => {
        console.log(res);
        this.powData = res.data

      },
      complete: () => {
        const timestamp = new Date(this.powData.created_at)
        // const timestamp = new Date("2023-03-27T11:19:50.000000Z");
        this.date = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear().toString()}`;
      }

    })
  }

  downloadImage() {
    const cardElement = this.cardToDownload.nativeElement;
    cardElement.style.display = 'block'; // hide the card element
    html2canvas(cardElement).then((canvas) => {
      // Convert canvas to image
      const image = canvas.toDataURL('image/png');
      // Create a download link
      const link = document.createElement('a');
      link.download = `${this.date}.png`;
      link.href = image;
      link.click();
      cardElement.style.display = 'none'; // show the card element again
    });
  }
}
