<nav class="navbar navbar-expand-sm navbar-light bg-secondary">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogowhite.png" alt="" width="70">
    </a>


  </div>
</nav>

<div class="container">
  <div class="row my-15" *ngIf="mintpageNFT">
    <div class="col-md-5">
      <img [src]="mintpageNFT?.nft.image" class="img-fluid  rounded nftlogo" alt="">
      <div class="my-15">
        <h2 class="mb-7">Details</h2>
        <p [innerHTML]="mintpageNFT?.nft.description"></p>
      </div>

      <div class="row justify-content-center">
        <div class="col">
          <p>Royalties</p>
          <h3> {{ mintpageNFT?.nft.secondary_royalties }}%</h3>
        </div>
        <div class="col">
          <p>Status</p>
          <h3>{{ mintpageNFT?.sale_status }}</h3>
        </div>
        <div class="col">
          <p>Edition Type</p>
          <h3>{{ transform(mintpageNFT?.nft.nft_type) }}</h3>
        </div>
      </div>

      <h2 class="my-15"> Properties</h2>
      <div class="row">
        <div class="col-3" *ngFor="let item of mintpageNFT?.nft.properties">
          <p> {{ item.trait_type }} </p>
          <h3> {{ item.value }} </h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 offset-md-1">

      <div *ngIf="mintpageNFT.sale_settings === 'pending'" class="card">
        <!-- <img src="assets/imgs/skeleton.png" class="card-img-top"> -->
        <div class="card-body ">
          <div class="skeleton-text" style="width: 100%;">
            <div class="skeleton-line" style="height: 30px;"></div>
          </div>
          <!-- <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                <div class="progress-bar bg-secondary progress-bar-striped progress-bar-animated" style="width: 100%"></div>
              </div> -->
          <br>
          <br>

          <div class="skeleton-text" style="width: 70%;">
            <div class="skeleton-line" style="height: 30px;"></div>
          </div>

          <!-- <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                <div class="progress-bar bg-secondary progress-bar-striped progress-bar-animated" style="width: 70%"></div>
              </div> -->


          <br><br>

          <div class="skeleton-text" style="width: 100%;">
            <div class="skeleton-line" style="height: 200px;"></div>
          </div>

          <!-- <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100" style="height: 100px">
                <div class="progress-bar bg-secondary progress-bar-striped progress-bar-animated" style="width: 100%"></div>
              </div> -->
        </div>
        <!-- <div class="skeleton-text">
              <div class="skeleton-line" *ngFor="let line of lines" [numberOfLines]="4" [lineWidth]="200" [lineHeight]="30">
              </div>
            </div> -->


      </div>
      <div *ngIf="mintpageNFT.sale_settings === 'completed'">
        <div class="card">
          <div class="p-15">
            <div class="d-flex align-items-center">
              <img [src]="mintpageNFT?.nft.image" width="70" class=" rounded me-7" alt="">
              <h2>{{ mintpageNFT?.nft.name| uppercase }}</h2>
            </div>

            <div class="bg-secondary mt-7 p-7 pt-15 rounded">
              <div class="row">
                <div class="col mb-3">
                  <p>Price</p>
                  <h4> {{ mintpageNFT?.external_price }} {{ mintpageNFT?.coin_symbol }} </h4>
                </div>
                <div class="col text-md-end text-lg-end text-xl-end mb-3">
                  <p> {{transform(mintpageNFT?.nft.nft_type)}} </p>
                  <h3>{{ mintpageNFT?.nft.max_supply || 'Unlimited' }}</h3>
                </div>
              </div>

              <div class="text-center mt-7">
                <button class="btn btn-primary btn-block py-5" [disabled]="mintpageNFT?.sale_status=== 'inactive'"
                  (click)="onClick(content)">MINT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Mint Details</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="mintForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" formControlName="email" class="form-control">
      </div>

      <div class="form-group">
        <label for="" class="form-label">Password</label>
        <input type="password" name="password" formControlName="password" class="form-control">
      </div>
      <a [href]="'/auth/registration'">Create a new Account</a>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="mint()">Submit</button>
  </div>
</ng-template>