import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionsDetailsService } from './transactions-details.service';

@Component({
  selector: 'app-transactions-details',
  templateUrl: './transactions-details.component.html',
  styleUrls: ['./transactions-details.component.scss']
})
export class TransactionsDetailsComponent implements OnInit {
  id: string | null;
  currentDateStr: string = new Date().getFullYear().toString();
  transactions: any;
  displaydata: boolean;
  showActivated: boolean;


  constructor(
    private route: ActivatedRoute,
    private tnxService: TransactionsDetailsService
  ) {
    this.id = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    this.getTrnx()
  }
  getTrnx() {
    this.tnxService.getTransactions(this.id).subscribe({
      next: (res: any) => {
        this.transactions = res.data
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.displaydata = true
        if (this.transactions.transfer_transaction.length === 0) {
          this.showActivated = true
        }
      }
    })
  }
  onLoad(ev: any) {
    console.log(ev)
  }

}
