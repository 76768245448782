 <!-- Global section for info start -->
<div class="alert alert-warning" role="alert" *ngIf="!twiterLinked && !discordLinked">
    You have not connected your twitter and discord <a href="/sc/account/connected_accounts">Connect</a>
</div>
<div class="alert alert-warning" role="alert" *ngIf="!twiterLinked && discordLinked">
  You have not connected your twitter account <a href="/sc/account/connected_accounts">Connect</a>
</div>
<div class="alert alert-warning" role="alert" *ngIf="!discordLinked && twiterLinked">
  You have not connected your discord account <a href="/sc/account/connected_accounts">Connect</a>
</div>
<div class="alert alert-warning" role="alert" *ngIf="userData?.social_tag === ''">
  Kindly update your Social connector username <a href="/sc/account/overview">Update SC Username</a>
</div>


<!-- Global section for info ends -->
<router-outlet></router-outlet>
