import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../../auth/services/token.service';
// @ts-ignore
const api = `${environment.social_pay_baseurl}`;
const apiUrl = `${environment.apiUrl}`;
const base_api = `${environment.base_api}`
// @ts-ignore
const gateway = `${environment.social_pay_gateway}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class SocialpayService {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // Function to get list of merchants
  getMerchants() {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/merchants`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getpayprocessor() {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/payment-processors`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // Function to connect merchant stripe

  connectStripe(id: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/connect-stripe/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // unlink stripe
  unlinkStripe(id: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/stripe/unlink/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // single merchant details
  singleMerchant(id: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/merchants/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // get merchants payment pages
  merchantPaymentsPages(id: any, p: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .get<any>(`${api}/payment_pages/merchant/${id}?page=${p}`, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  allmerchantPaymentsPages(id: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .get<any>(`${api}/payment_pages/merchant/${id}/all`, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  // Function to get details of a payment page
  getPaymentsPageDetails_1(id: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/payment_pages/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  // Function to get details of a payment page
  getPaymentsPageDetails(id: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/payment_pages/show/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // get payment details with link by unregistered user
  getPaymentsPageDetailsWithLink(token: any) {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/pay/${token}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // function to get list of transactions
  getPaymentsPageTransactions(id: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .get<any>(`${api}/transaction/payment-pages/${id}`, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  // Function to get currency type
  getCurrencyType() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${apiUrl}/solana/coin/role`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
    // return this.http.get<any>(`${api}/all-coins`, httpOptions).pipe(
    //   map((rs) => {
    //     return rs;
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }
  // Function to add merchant
  addMerchant(payload: any) {
    return this.http.post(`${api}/merchants`, payload, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // Function to edit merchant
  editMerchant(payload: any, id: any) {
    return this.http.patch(`${api}/merchants/${id}`, payload, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // Update keys
  updateMerchantKey(id: any) {
    this.isLoadingSubject.next(true)
    return this.http.patch(`${api}/merchants/keys/${id}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // Function to add payment page form
  addPaymentPageForm(payload: any) {
    return this.http.post(`${api}/payment_pages`, payload, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // Function to update payment form
  updatePaymentPageForm(payload: any, id: any) {
    return this.http
      .patch(`${api}/payment_pages/${id}`, payload, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  deletePaymentPage(page_link: any) {
    return this.http.delete(`${api}/payment_pages/delete/${page_link}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  // Function toinitialize payment
  initPayment(data: any) {
    return this.http
      .post(`${api}/transaction/init`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // init stripe payment function
  initStripePayment(data: any) {
    return this.http
      .post(`${api}/transaction/init-stripe`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // Verify stripe payment
  verifyStripePayment(data: any) {
    return this.http
      .post(`${api}/transaction/init-stripe`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }



  validateAta(data: any) {
    return this.http
      .post(`${gateway}validateata`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  // Function togenerate transactions
  generateTnx(data: any) {
    return this.http
      .post(`${gateway}generate`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  
  // Function to get socialPay details
  getSocialPayTnx(payload: any) {
    return this.http
      .post(`${base_api}/solana/generate-socialpay`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getTnxByRef(ref: string) {
    return this.http
      .get(`${api}/transaction/fetch/${ref}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getInlineTnxByRef(ref: string) {
    return this.http
      .get(`${api}/transaction/fetch-inline/${ref}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  findTnx(data: any) {
    return this.http
      .post(`${gateway}findtransaction`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  validateTnx(data: any) {
    return this.http
      .post(`${gateway}validatetransaction`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  filterTransactions(data: any, page: any) {
    this.isLoadingSubject.next(true)
    return this.http.post(`${api}/transaction/all?page=${page}`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  updateMerchantInfo(data: any, id: any) {
    this.isLoadingSubject.next(true)
    return this.http.patch(`${api}/merchants/merchant-update/${id}`, data).pipe(finalize(() => this.isLoadingSubject.next(false)))

  }

  getCustodilWallet(data: any) {
    this.isLoadingSubject.next(true)
    return this.http.post(`${apiUrl}/solana/get-custodial-wallet`, data).pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  getSignedTnx(data: any) {
    this.isLoadingSubject.next(true)
    return this.http.post(`${apiUrl}/solana/sign-transaction`, data).pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
}
