<div class="row">
  <div class="col-md-5">
    <div class="card">
      <div class="card-header">
        <div class="row w-100">
          <div class="py-7">
            <h1>Create landing page</h1>
            <p class="mb-0">Fill the form below to create your landing page</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <form
              name="form"
              (ngSubmit)="f.form.valid && submit(f.value)"
              #f="ngForm"
              novalidate
            >
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label for="" class="form-label">Coin</label>
                    <input
                      type="text"
                      class="form-control"
                      name="coin_symbol"
                      [(ngModel)]="addForm.coin_symbol"
                      required
                      readonly
                      #coin_symbol="ngModel"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" class="form-label">Amount</label>
                    <input
                      type="number"
                      class="form-control"
                      name="amount"
                      [(ngModel)]="addForm.amount"
                      required
                      #amount="ngModel"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" class="form-label">Status</label>
                    <select
                      name="status"
                      class="form-select"
                      [(ngModel)]="addForm.status"
                      required
                      #status="ngModel"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="text-end mt-10">
                <button
                  [disabled]="!f.form.valid"
                  class="btn btn-primary btn-block"
                  *ngIf="referral_link.length == 0"
                >
                  Create Landing Page
                </button>
                <button
                  [disabled]="!f.form.valid"
                  class="btn btn-primary btn-block"
                  *ngIf="referral_link.length > 0"
                >
                  Update Landing Page
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7">
    <div class="card mb-5 mb-xl-10" *ngIf="referral_link.length > 0">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div class="me-7 mb-4">
            <div
              class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
            >
              <img
                [src]="referral_link[0].coin_image"
                onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                alt="Metronic"
              />
            </div>
          </div>
          <div class="flex-grow-1">
            <div
              class="d-flex justify-content-between align-items-start flex-wrap mb-2"
            >
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a
                    class="text-gray-800 text-hover-primary fs-1 fw-bolder me-1 cursor-pointer"
                  >
                    {{ referral_link[0].coin_symbol }}
                  </a>
                  <a class="cursor-pointer">
                    <span class="svg-icon svg-icon-1 svg-icon-primary"></span>
                  </a>
                </div>

                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <a
                    class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 cursor-pointer"
                    (click)="
                      copy(
                        'https://gary.club/landing/' +
                          referral_link[0].coin_symbol
                      )
                    "
                  >
                    <span class="svg-icon svg-icon-4 me-1"></span>
                    {{
                      "https://gary.club/landing/" +
                        referral_link[0].coin_symbol
                    }}
                  </a>
                </div>
              </div>
            </div>

            <!-- total_creator_rewards -->

            <div class="d-flex flex-wrap flex-stack">
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <div class="d-flex flex-wrap">
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <span
                        class="svg-icon svg-icon-3 svg-icon-primary me-2"
                      ></span>
                      <div class="fs-2 fw-bolder">
                        estimated_gary_reward_after_fee
                      </div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">
                      $BAGS Rewards
                      <i
                        class="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Rewards are earned as a creator or for holding $BAGS"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    *ngIf="viewCreatorSup"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="svg-icon svg-icon-3 svg-icon-primary me-2"
                      ></span>
                      <div class="fs-2 fw-bolder">supporters</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">Supporters</div>
                  </div>

                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="svg-icon svg-icon-3 svg-icon-primary me-2"
                      ></span>
                      <div class="fs-2 fw-bolder">djjd</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">
                      Creator Rewards
                    </div>
                  </div>

                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <span
                        class="svg-icon svg-icon-3 svg-icon-primary me-2"
                      ></span>
                      <div class="fs-2 fw-bolder">total_referred</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">
                      Total Referred
                      <i
                        class="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="The total number of active referrals"
                      ></i>
                    </div>
                  </div>

                  <!-- <div class="
                        border border-gray-300 border-dashed
                        rounded
                        min-w-125px
                        py-3
                        px-4
                        me-6
                        mb-3
                      " *ngIf="_user.coin">
                      <div class="d-flex align-items-center">
                        <span [inlineSVG]="
                            './assets/media/icons/duotune/arrows/arr066.svg'
                          " class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                        <div class="fs-2 fw-bolder">15%</div>
                      </div>
      
                      <div class="fw-bold fs-6 text-gray-400">Supporters'  change</div>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr
                  class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                  style="cursor: pointer"
                >
                  <th>S/N</th>
                  <th class="ps-7">Coin Name</th>
                  <th>Balance</th>
                  <th>Balance ($)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="ps-7">
                    <img
                      [src]="'assets/imgs/solana.png'"
                      width="30"
                      class="rounded-circle"
                      alt=""
                      srcset=""
                      onerror="this.onerror=null;this.src='assets/imgs/background.jpg'"
                    />
                    &nbsp;&nbsp; SOL
                  </td>
                  <td>djdj</td>
                  <td>jfjfj</td>
                  <td>
                    <button class="btn btn-sm btn-primary">Send coin</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-7">
                    <img
                      width="30"
                      height="30"
                      class="rounded-circle"
                      alt=""
                      srcset=""
                      onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                    />&nbsp;&nbsp; dkdk
                  </td>
                  <td>jdjd</td>
                  <td>kfkfk</td>
                  <td>
                    <button class="btn btn-sm btn-primary">Send coin</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true" [responsive]="true"
            (pageChange)="p = $event"></pagination-controls> -->
        </div>
      </div>
    </div>
  </div>
</div>
