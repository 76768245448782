import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor() {}

  private childClickedEvent = new BehaviorSubject<string>('');

  emitChildEvent(msg: string) {
    this.childClickedEvent.next(msg);
  }

  childEventListner() {
    return this.childClickedEvent.asObservable();
  }
}
