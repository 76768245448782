import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../auth/services/token.service';
// const api = `${environment.social_pay_baseurl}`;
const apiUrl = `${environment.apiUrl}`;
const base_api = `${environment.base_api}`
// @ts-ignore
const gateway = `${environment.social_pay_gateway}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  userData: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.userData = this.token.getUser()
    // console.log(a)
  }

  saveToPOW(data) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${apiUrl}/exchange/proof-of-work/store`, data, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  createPowConfig(payload: any) {
      this.isLoadingSubject.next(true);
      return this.http.post(`${apiUrl}/exchange/proof-of-work/store-config`, payload, httpOptions).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      )
  }

  getReferralLink() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${apiUrl}/user/creator/referral-link`, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false)))
  }
  addLandPage(payload: any) {
    return this.http
      .post<any>(`${apiUrl}/user/creator/create-referral-link`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
 


  getSinglePOW(id) {
    this.isLoadingSubject.next(true);
    return this.http.get(`${apiUrl}/exchange/proof-of-work-details/${id}`, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false)))
  }
}
