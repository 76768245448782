import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPopupComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() iframeSrc: string = '';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  closeModal() {
    this.showModal = false;
  }
  
  safeIframeSrc(): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }

}
