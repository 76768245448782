import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEditorModule } from 'ngx-editor';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TagInputModule } from 'ngx-chips';
import { MatIconModule } from '@angular/material/icon';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TowersRoutingModule } from './towers.routing.module';
import { TowersComponent } from './overview/overview.component';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
   TowersComponent,
   ModalPopupComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    FormsModule,
    NgxPaginationModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    TowersRoutingModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxEditorModule,
    TagInputModule,
    MatIconModule,
    NgbModalModule
  ]
})
export class TowersModule { }
