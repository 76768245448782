import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

export type UserType = any | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  private authdetailsLocalStorageToken = `userdetails`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  verify_Otp: boolean = false;
  userdata: any;
  emailChanged_res: any;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private token: TokenService,
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // First timer watch video guide


  // public methods
  login(payload: any): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(payload).pipe(
      map((user) => {
        if (user?.data?.otp_required === true) {
          this.verify_Otp = true;
          this.userdata = user;
          return user
        } else {
          const result = this.token.setAuthFromLocalStorage(user);
          this.currentUserSubject.next(user);
          this.getUserByToken();
          return user;
        }
      }),
      catchError((err) => {
        return of(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  otpVerify(payload: any): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.otplogin(payload).pipe(
      map((user) => {
        // const result = this.token.setAuthFromLocalStorage(user);
        // this.currentUserSubject.next(user);
        return user;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        // console.error('err', err);
        return of(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.currentUserSubject.next(null);
    const returnUrl = location.pathname;
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.authdetailsLocalStorageToken);
    localStorage.removeItem('onboarding_process');
    this.router.navigate(['/auth/login'], {
      queryParams: { return: returnUrl },
    });
  }

  verifyEmail(otp: any): Observable<UserType> {
    const auth = this.token.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verifyEmail(otp, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changeEmail(data: any): Observable<UserType> {
    const auth = this.token.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.changeEmail(data, auth.data.token).pipe(
      catchError((err) => {
        console.error('err', err);
        if (err.error.error?.email[0]) {
          const message = err.error.error?.email[0];
          Swal.fire('Failed', message, 'error');
        } else {
          Swal.fire('Failed', err.error.message, 'error');
        }
        return of(err);
      }),
      map((res) => {
        this.emailChanged_res = res;
        if (res.success === true) {
          Swal.fire('Success', res.message, 'success');
          localStorage.removeItem(this.authLocalStorageToken);
          this.router.navigate(['/']);
          return res;
        }
      }),
      switchMap((): any => {
        console.log(this.emailChanged_res);
        if (this.emailChanged_res['success'] === true) {
          console.log(this.emailChanged_res, 'login function next');
          return this.login(data);
        } else if (this.emailChanged_res['success'] === false) {
          console.log(this.emailChanged_res)
          return {};
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resendOTP(): Observable<UserType> {
    const auth = this.token.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resendOTP(auth.data.customer.email, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.token.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    if (auth.data == null || auth.data.token === null) {
      // console.log('no token');
      // this.router.navigateByUrl('auth');
      this.logout();
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.data.token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map((rs) => {
        return rs;
      }),
      switchMap(() => this.login(user)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(data: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(data)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  // private setAuthFromLocalStorage(auth: any): boolean {
  //   // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
  //   if (auth) {
  //     localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
  //     return true;
  //   }
  //   return false;
  // }

  // public getAuthFromLocalStorage() {
  //   try {
  //     const lsValue = localStorage.getItem(this.authLocalStorageToken);
  //     if (!lsValue) {
  //       return undefined;
  //     }

  //     const authData = JSON.parse(lsValue);
  //     return authData;
  //   } catch (error) {
  //     console.error(error);
  //     return undefined;
  //   }
  // }

  getAuth() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      // console.log(authData.data.token);
      const a = authData.data.token;
      return a;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getJupiterCoins() {

    return this.http.get('https://cache.jup.ag/tokens')

  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
