/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-menu-custom',
  templateUrl: './dropdown-menu-custom.component.html',
  styleUrls: ['./dropdown-menu-custom.component.scss'],
})
export class DropdownMenuCustomComponent implements OnInit {
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  @Output() onDatePicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}
  test(date: any): void {
    this.onDatePicked.emit(date);
  }
}
