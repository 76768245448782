import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const api = environment.base_api;
@Injectable({
  providedIn: 'root'
})
export class MigrationCheckerService {

  constructor(
    private http: HttpClient
  ) { }


  getMigrationStatus(id) {
    return this.http.get(`${api}/ethereum/check/${id}`).pipe()
  }
}
