import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../../auth/services/token.service';
const userUrl = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
"Accept": "*" }),
};

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) { 
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }
  // get instagram profile details
  getInstagramProfile(username: string) {
    return this.http.get(`${userUrl}/instagram/get-user-id/${username}`, httpOptions).pipe()
  }

  // Drops
  createInstagramDrop(data: any) {
    return this.http
      .post(userUrl + `/instagram/drops/create-drop`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  monitorPost(url: any, name: any, status: any) {
    let payload = {
      title: name,
      url: url ,
      status: status
    }
    return this.http
      .post(userUrl + `/instagram/business/post/create-post`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  updatemonitorPost(url: any, name: any, status: any, id: any) {
    let payload = {
      title: name,
      url: url ,
      status: status
    }
    return this.http
      .put(userUrl + `/instagram/business/post/monitored-posts/${id}`, payload, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  editInstagramDrop(drop_id: any, data: any) {
    return this.http
      .patch(userUrl + `/instagram/drops/edit-drop/${drop_id}`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getInstragramDrops() {
    return this.http.get(`${userUrl}/instagram/drops/list-drop`, httpOptions).pipe()
  }
  
  getInstagramClaimedDrop(id: any) {
    return this.http.get(`${userUrl}/instagram/drops/drops-claimed/${id}`, httpOptions).pipe()
  }
  getMonitoredPosts() {
    return this.http.get(`${userUrl}/instagram/business/post/monitored-posts`, httpOptions).pipe()
  }

  getStat() {
    return this.http.get(`${userUrl}/instagram/dashboard-stats`, httpOptions).pipe()
  }

  watchVideo() {
    return this.http.get(`${userUrl}/user/update-watch-video`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
