import { Component, OnInit } from '@angular/core';
import { MigrationCheckerService } from './migration-checker.service';

@Component({
  selector: 'app-migration-checker',
  templateUrl: './migration-checker.component.html',
  styleUrls: ['./migration-checker.component.scss']
})
export class MigrationCheckerComponent implements OnInit {

  id: any
  showDet: boolean = false
  giraffeDetails: any;
  isMigrated: boolean;
  message: any;
  constructor(
    private service: MigrationCheckerService
  ) { }

  ngOnInit(): void {
  }


  submit() {
    console.log(this.id)
    this.service.getMigrationStatus(this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        const data = res
        this.message = data.message
        this.giraffeDetails = data.data
        if (Object.keys(this.giraffeDetails).length > 0) {
          this.isMigrated = true
        } else this.isMigrated = false

      },
      error: (err: any) => console.log(err),
      complete: () => {
        this.showDet = true
      }
    })
  }

  cancel() {
    this.showDet = false
  }

}
