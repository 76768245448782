<ng-container *ngIf="user$ | async as _user">
  <!-- <div class="menu-item px-3" style="overflow-x: hidden !important">
    <div class="menu-content d-flex align-items-center px-3">
      <div class="symbol symbol-50px me-5">
        <img alt="Logo" [src]="_user.sc_profile_picture" />
      </div>

      <div class="d-flex flex-column">
        <div class="fw-bolder d-flex align-items-center fs-5">
          {{ _user.name }}
          <span
            class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
            *ngIf="_user.coin !== null"
          >
            <span
              [inlineSVG]="'./assets/media/icons/duotune/general/gen026.svg'"
              class="svg-icon svg-icon-1 svg-icon-primary"
            ></span>
          </span>
        </div>
        <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer">
          {{ _user.email }}
        </a>
      </div>
    </div>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-5">
    <a routerLink="/sc/account/overview" class="menu-link px-5"> My Profile </a>
  </div>

  <div
    class="menu-item px-5"
    data-kt-menu-trigger="hover"
    data-kt-menu-placement="left-start"
    data-kt-menu-flip="bottom"
    *ngIf="team.length > 0"
  >
    <a class="menu-link px-5 cursor-pointer">
      <span class="menu-title">My Teams</span>
      <span class="menu-arrow"></span>
    </a>

    <div class="menu-sub menu-sub-dropdown w-175px py-4">
      <div class="menu-item px-3" *ngFor="let items of team">
        <a
          class="menu-link px-5 cursor-pointer"
          (click)="switchTeam(items.team_id)"
        >
          {{ items.team_name }}
        </a>
      </div>

      <div class="separator my-2"></div>

      <div class="menu-item px-3">
        <a class="menu-link px-5 cursor-pointer" (click)="switchTeam(_user.id)">
          My Team</a
        >
      </div>
    </div>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-5 my-1">
    <a routerLink="/sc/account/settings" class="menu-link px-5">
      Account Settings
    </a>
  </div>

  <div class="menu-item px-5">
    <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
  </div> -->

  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <div
      class="menu-item px-3"
      style="overflow-x: hidden !important"
      ngbDropdownItem
    >
      <div class="menu-content d-flex align-items-center px-3">
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ _user.name }}
            <span
              class="badge badge-clear fw-bolder fs-8 px-2 py-1 ms-2"
              *ngIf="_user.coin !== null"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen026.svg'"
                class="svg-icon svg-icon-1 svg-icon-primary"
              ></span>
            </span>
          </div>
          <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer">
            {{ _user.email }}
          </a>
        </div>
      </div>
    </div>
    <div class="separator my-2"></div>
    <div class="menu-item px-5" ngbDropdownItem>
      <a routerLink="/sc/account/overview" class="menu-link px-5">
        My Profile
      </a>
    </div>

    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom"
      *ngIf="team.length > 0"
      ngbDropdownItem
    >
      <a class="menu-link px-5 cursor-pointer">
        <span class="menu-title">My Teams</span>
        <span class="menu-arrow"></span>
      </a>

      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <div class="menu-item px-3" *ngFor="let items of team">
          <a
            class="menu-link px-5 cursor-pointer"
            (click)="switchTeam(items.team_id)"
          >
            {{ items.team_name }}
          </a>
        </div>

        <div class="separator my-2"></div>

        <div class="menu-item px-3">
          <a
            class="menu-link px-5 cursor-pointer"
            (click)="switchTeam(_user.id)"
          >
            My Team</a
          >
        </div>
      </div>
    </div>

    <div class="menu-item px-5 my-1" ngbDropdownItem>
      <a routerLink="/sc/account/settings" class="menu-link px-5">
        Account Settings
      </a>
    </div>
    <div class="separator my-2"></div>

    <div class="menu-item px-5" ngbDropdownItem>
      <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
    </div>
  </div>
</ng-container>
