<div class="menu-item px-3">
  <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">
    Quick Actions
  </div>
</div>

<div class="separator mb-3 opacity-75"></div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer" (click)="test('price')"> Price </a>
</div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer" (click)="test('price$')"> Price ($) </a>
</div>


<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer" (click)="test('volume')"> Volume </a>
</div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer" (click)="test('tvl')"> TVL </a>
</div>
