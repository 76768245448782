<div class="row mt-15" *ngIf="powData">
  <div class="col-md-8 offset-md-2">
    <div class="card bg">
      <div class="card-body">
        <div class="head mb-15">
          <div class="row align-items-center">
            <div class="col-md-1 d-none d-sm-block">
              <img src="assets/imgs/garylight.png" alt="" srcset="">
            </div>
            <div class="col-md-10 text-center">
              <h3>Proof Of Work</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="form-group">
              <label for="" class="form-label">Did you grow your audience today?</label>
              <input type="text" class="form-control form-control-solid form-control-lg" [value]="powData.grow_audience"
                disabled>
            </div>

            <div class="form-group">
              <label for="" class="form-label">Did you make money today?</label>
              <input type="text" class="form-control form-control-solid form-control-lg" [value]="powData.make_money"
                disabled>
            </div>
            <div class="form-group">
              <label for="" class="form-label">Your Work</label>
              <div class="card work">
                <div class="card-body">
                  <ul>
                    <li *ngFor="let item of powData.work_summary"> {{ item }} </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="" class="form-label">Intensity Level</label>
              <div>
                <span *ngFor="let circle of [1, 2, 3, 4, 5]" class="intensity-circle"
                  [class.filled]="circle <= powData.intensity"></span>
              </div>
            </div>
            <!-- <div class="form-group" *ngIf="powData.note">
              <label for="" class="form-label">Notes</label>
              <p [innerHTML]="powData.note" class="note"></p>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="text-end mt-7">
      <a class=" me-7" (click)="downloadImage()" style="cursor: pointer;">Download POW</a>
      <!-- <a href="https://twitter.com/intent/tweet?text=I%20just%20got%20paid%20proof%20of%20work%20today"
        target="_blank">Share on Twitter</a> -->
      <!-- <a href="https://twitter.com/intent/tweet?text=I%20just%20got%20paid%20%24GARY%20for%20being%20a%20creator%21%20%23GaryClub%20https%3A%2F%2Fexample.com%2Fyour-link-here"
        target="_blank">Share on Twitter</a> -->
      <a href="https://twitter.com/intent/tweet?text=I%20just%20got%20paid%20%24GARY%20for%20being%20a%20creator%21%20%23GaryClub"
        target="_blank">Share on Twitter</a>


      <!-- <a href="" target="_blank">Share on Twitter</a> -->
    </div>
  </div>
</div>



<div *ngIf="powData">
  <div class="card bg test" #cardToDownload style="display: none;">
    <div class="card-body">
      <div class="head mb-15">
        <div class="row align-items-center">
          <div class="col-1 ">
            <img src="assets/imgs/garylight.png" alt="" srcset="">
          </div>
          <div class="col-10 text-center">
            <h3>Proof Of Work</h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-8 offset-2">
          <div class="form-group">
            <label for="" class="form-label">Did you grow your audience today?</label>
            <input type="text" class="form-control form-control-solid form-control-lg" [value]="powData.grow_audience"
              disabled>
          </div>

          <div class="form-group">
            <label for="" class="form-label">Did you make money today?</label>
            <input type="text" class="form-control form-control-solid form-control-lg" [value]="powData.make_money"
              disabled>
          </div>
          <div class="form-group">
            <label for="" class="form-label">Your Work</label>
            <div class="card work">
              <div class="card-body">
                <ul>
                  <li *ngFor="let item of powData.work_summary"> {{ item }} </li>


                </ul>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="form-label">Intensity Level</label>
            <div>
              <span *ngFor="let circle of [1, 2, 3, 4, 5]" class="intensity-circle"
                [class.filled]="circle <= powData.intensity"></span>
            </div>
          </div>
          <!-- <div class="form-group" *ngIf="powData.note">
            <label for="" class="form-label">Notes</label>
            <p [innerHTML]="powData.note" class="note"></p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>