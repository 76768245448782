    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Welcome {{userDetails}} Kindly Watch the User Guide</h4>
    </div>
  <div class="modal-body my-3">
    <div class="">
            <div class="video row">
                <iframe src="https://app.searchie.io/watch/mpqzOEOx2K" height="600px"   title="User guide" ></iframe>
            </div>
    </div>
    <div class="text-end mt-2">
        <button class="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-5"
          type="reset" (click)="watchVideo()" >
          Skip Guide
        </button>
        <button (click)="watchVideo()" class="btn btn-primary py-3 px-5">
            Done Watching
        </button>
     </div>
 </div>
