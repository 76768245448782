import { Injectable } from '@angular/core';
// import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '../../utils/index';
// import { Token } from '@solana/spl-token';
import {
  WalletAdapterNetwork,
  WalletName,
  WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ConnectionStore, WalletStore } from '@heavy-duty/wallet-adapter';
import { AnchorProvider, Provider } from '@project-serum/anchor';
import base58 from 'bs58';

import {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
  Connection,
  clusterApiUrl,
} from '@solana/web3.js';
import { concatMap, first, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import NodeWallet from '@project-serum/anchor/dist/cjs/nodewallet';
const gateway = `${environment.social_pay_gateway}`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class WalletService {
  readonly wallets$ = this._hdWalletStore.wallets$;
  readonly wallet$ = this._hdWalletStore.wallet$;
  readonly walletName$ = this.wallet$.pipe(
    map((wallet) => wallet?.adapter.name || null)
  );
  readonly walletIcon$ = this.wallet$.pipe(
    map((wallet) => wallet?.adapter.icon || null)
  );
  readonly ready$ = this.wallet$.pipe(
    map(
      (wallet) =>
        wallet &&
        (wallet.adapter.readyState === WalletReadyState.Installed ||
          wallet.adapter.readyState === WalletReadyState.Loadable)
    )
  );
  readonly connected$ = this._hdWalletStore.connected$;
  readonly publicKey$ = this._hdWalletStore.publicKey$;
  connection: any;
  provider: any;
  wallet: any;
  // alert: string = null;
  tnxlink: string;
  refresh: NodeJS.Timeout;
  menuItems3: any;
  rpc_url: any;
  rpc_ws: any;
  exchange_status: any;
  exchange_message: any;
  exchange_offline_message: any;
  exchange_wormhole_status: any;
  exchange_wormhole_offline_message: any;
  exchange_wormhole_message: any;
  hdwallet: WalletStore;
  bagBalance: any;
  showPrivate: boolean;

  public tokenABalance = 0;
  public tokenBBalance = 0;

  constructor(
    private readonly _hdConnectionStore: ConnectionStore,
    private readonly _hdWalletStore: WalletStore,
    private http: HttpClient,
  ) {
    this.getWormholeData().subscribe({
      next: (res: any) => {
        // //console.log(res.data);
        this.rpc_url = res.data.rpc_url.config_value;
        this.rpc_ws = res.data.rpc_ws_url.config_value;
        this.exchange_status = res.data.exchange_status.config_value;
        this.exchange_message = res.data.exchange_message.config_value;
        this.exchange_offline_message =
          res.data.exchange_offline_message.config_value;
        this.exchange_wormhole_status =
          res.data.exchange_wormhole_status.config_value;
        this.exchange_wormhole_message =
          res.data.exchange_wormhole_message.config_value;
        this.exchange_wormhole_offline_message =
          res.data.exchange_wormhole_offline_message.config_value;
        this.connection = new Connection(this.rpc_url, {
          commitment: 'confirmed',
          wsEndpoint: this.rpc_ws,
          confirmTransactionInitialTimeout: 120000,
        });

        this._hdWalletStore.anchorWallet$.subscribe((wallet) => {
          this.wallet = wallet;
          // console.log(this.wallet);
          const walletKeyPair = Keypair.generate();
          this.provider = new AnchorProvider(this.connection,new NodeWallet(walletKeyPair), {
            commitment: 'confirmed',
          });
          // //console.log(this.provider);
          this.hdwallet = this._hdWalletStore;
          // this.getBags();
        });

        // //console.log(this.menuItems2);
      },
    });
  }

  getWormholeData() {
    return this.http.get(
      environment.base_api + `/solana/wormholeconfig`,
      httpOptions
    );
  }

  initialize() {
    this._hdConnectionStore.setEndpoint(environment.solana_api);
    this._hdWalletStore.setAdapters([
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
      new SolletExtensionWalletAdapter({
        network: WalletAdapterNetwork.Devnet,
      }),
    ]);
  }

  onConnect() {
    this._hdWalletStore.connect().subscribe();
    // this.getBags();
  }

  onDisconnect() {
    // this.resetBalance();
    this._hdWalletStore.disconnect().subscribe();
  }

  onSelectWallet(walletName: WalletName) {
    // //console.log(this._hdWalletStore);

    this._hdWalletStore.selectWallet(walletName);
  }
  resetBalance() {
    // console.log('resetting...');
    this.tokenABalance = 0;
    this.tokenBBalance = 0;
    this.showPrivate = false;
  }

  onSignMessage() {
    const signMessage$ = this._hdWalletStore.signMessage(
      new TextEncoder().encode('Hello world!')
    );

    if (!signMessage$) {
      Swal.fire('', 'Sign message method is not defined', 'error')
      // this.alertservice.danger('Sign message method is not defined');
      return console.error(new Error('Sign message method is not defined'));
    }

    signMessage$.pipe(first()).subscribe((signature) => {
      Swal.fire('', `Message signature: ${base58.encode(signature)}`, 'error')
      // this.alertservice.danger(
      //   `Message signature: ${base58.encode(signature)}`
      // );
    });
  }


  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  getSignedTnx(data: any) {
    return this.http.post(`${environment.base_api}/solana/sign-transaction-bearer`, data);
  }

  validateAta(data: any) {
    return this.http
      .post(`${gateway}validateatasc`, data, httpOptions)
  }
}
