<!-- Search toolbar -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <app-search-result-inner></app-search-result-inner>
</div> -->

<!-- Activities -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"> -->
<!-- begin::Drawer toggle -->
<!-- <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" id="kt_activities_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div> -->
<!-- end::Drawer toggle -->
<!-- </div> -->

<!-- NOTIFICATIONS -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <app-notifications-inner></app-notifications-inner>
</div> -->

<!-- CHAT -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"> -->
<!-- begin::Menu wrapper -->
<!-- <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
    id="kt_drawer_chat_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
    <span class="
        bullet bullet-dot
        bg-success
        h-6px
        w-6px
        position-absolute
        translate-middle
        top-0
        start-50
        animation-blink
      "></span>
  </div> -->
<!-- end::Menu wrapper -->
<!-- </div> -->

<!-- Quick links -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="click"
    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <app-quick-links-inner></app-quick-links-inner>
</div> -->

<div class="mt-3">
  <button type="button" class="btn btn-block btn-gradient ml-auto mr-1" (click)="handleModalToggle()">
    Buy SOL
  </button>

  <app-modal-popup [showModal]="showModal" *ngIf="iframeSrc" [iframeSrc]="iframeSrc"></app-modal-popup>
</div>

<!-- begin::Theme mode -->
<app-theme-mode-switcher class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"
  toggleBtnClass="{`btn-active-light-primary btn-custom ${toolbarButtonHeightClass}`}">
</app-theme-mode-switcher>
<!-- end::Theme mode -->

<!-- begin::User -->
<!-- <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass" id="kt_header_user_menu_toggle"
  *ngIf="user$ | async as _user">
  <div class="cursor-pointer symbol" [ngClass]="toolbarUserAvatarHeightClass" data-kt-menu-trigger="click"
    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <img [src]="_user.sc_profile_picture" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
      alt="profile Pic" />
  </div>
  <app-user-inner></app-user-inner>
</div> -->

<div ngbDropdown container="body" class="d-inline-block menu-gray-600" *ngIf="user$ | async as _user">
  <button type="button" class="btn btn-clear btn-sm" id="dropdownBasic1" ngbDropdownToggle>
    <div class="cursor-pointer symbol" [ngClass]="toolbarUserAvatarHeightClass">
      <img [src]="_user.sc_profile_picture" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
        alt="profile Pic" />
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <div class="menu-item px-3" style="overflow-x: hidden !important" ngbDropdownItem>
      <div class="menu-content d-flex align-items-center px-3">
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ _user.name }}
            <span class="badge badge-clear fw-bolder fs-8 px-2 py-1 ms-2" *ngIf="_user.coin !== null">
              <span [inlineSVG]="'./assets/media/icons/duotune/general/gen026.svg'"
                class="svg-icon svg-icon-1 svg-icon-primary"></span>
            </span>
          </div>
          <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer">
            {{ _user.email }}
          </a>
        </div>
      </div>
    </div>
    <div class="separator my-2"></div>
    <div class="menu-item px-5" ngbDropdownItem>
      <a [href]="'https://gary.club/' + _user.social_tag" target="_blank" class="menu-link px-5">
        My Profile
      </a>
    </div>

    <div class="menu-item px-5" ngbDropdownItem>
      <a class="menu-link px-5" routerLink="/sc/teams"> My Teams </a>

      <!-- <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <div class="menu-item px-3" *ngFor="let items of team">
          <a
            class="menu-link px-5 cursor-pointer"
            (click)="switchTeam(items.team_id)"
          >
            {{ items.team_name }}
          </a>
        </div>

        <div class="separator my-2"></div>

        <div class="menu-item px-3">
          <a
            class="menu-link px-5 cursor-pointer"
            (click)="switchTeam(_user.id)"
          >
            My Team</a
          >
        </div>
      </div> -->
    </div>

    <div class="menu-item px-5 my-1" ngbDropdownItem>
      <a routerLink="/sc/account/settings" class="menu-link px-5">
        Account Settings
      </a>
    </div>
    <div class="separator my-2"></div>

    <div class="menu-item px-5" ngbDropdownItem>
      <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
    </div>
  </div>
  <!-- <app-user-inner></app-user-inner> -->
</div>

<!-- end::User -->

<!-- <ng-container *ngIf="headerLeft === 'menu'">
  <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
    <div class="
        btn btn-icon btn-active-light-primary
        w-30px
        h-30px
        w-md-40px
        h-md-40px
      " id="kt_header_menu_mobile_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'" class="svg-icon svg-icon-1"></span>
    </div>
  </div>
</ng-container> -->