<nav class="navbar navbar-expand-sm navbar-light bg-transparent">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garyLogo.png" alt="" srcset="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarID" aria-controls="navbarID"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

  </div>
</nav>


<div class="container">
  <div class="row">
    <div class="col-md-4 offset-md-4">

      <div class="card">
        <div class="p-12 text-center">
          <div class="img mb-7">
            <img src="assets/imgs/migration_logo.png" alt="" srcset="">
          </div>
          <h1>Migration Checker</h1>

          <div class="row text-start mt-9" *ngIf="!showDet">
            <div class="col-md-6 offset-md-3">
              <div class="form-group mb-5">
                <label for="" class="form-label">Giraffe ID</label>
                <input type="text" class="form-control" [(ngModel)]="id" name="id">
              </div>
              <div class="text-center">
                <button class="btn btn-primary" (click)="submit()">Check</button>
              </div>
            </div>
          </div>
          <div class="row mt-9 text-start" *ngIf="showDet">
            <div class="col-12">
              <div class="border border-success rounded p-3" *ngIf="isMigrated">
                <div class="d-flex align-items-center">
                  <div class="pe-3 ">
                    <img [src]="giraffeDetails.image" width="70" class="rounded">
                  </div>
                  <div class="d-block">
                    <h4> {{ giraffeDetails.name }} </h4>
                    <p class="mb-0 pb-0 text-success"> {{ message }} </p>
                  </div>
                  <div class=" rounded-circle bg-success py-2 px-3 ms-auto">
                    <i class="fas fa-check text-white"></i>
                  </div>
                </div>
              </div>
              <div class="border border-danger rounded p-3" *ngIf="!isMigrated">
                <div class="d-flex align-items-center">
                  <div class="d-block">
                    <h3>Giraffe #{{ id }} </h3>
                    <p class="mb-0 pb-0 text-danger"> {{ message }} </p>
                  </div>
                  <div class=" rounded-circle bg-danger py-2 px-3 ms-auto">
                    <i class="fas fa-times text-white"></i>
                  </div>
                </div>

              </div>
              <div class="text-center">
                <button class="btn btn-clear text-primary" (click)="cancel()">Cancel</button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
