import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';
import { CompleteregService } from '../../completereg/completereg.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private service: CompleteregService) { }
  continueProcess(user: any) {
    this.service.checkStakeStatus().subscribe({
      next: (res: any) => {
        let status = res.data
        localStorage.setItem('stake_status', JSON.stringify(status.activated_stake))

        if (user?.data?.otp_required === true) {
          // this.loginFormClass = false;
          // this.showOtp = true;
        } else {
          if (user?.error) {
            // Swal.fire('Error', user?.error?.message, 'error');
            // return;
          }
          if (user?.data?.customer?.email_verified_at === null) {
            // this.router.navigateByUrl('/auth/verify-email');
          }
          else if (
            (user?.data?.customer?.email_verified_at !== null) &&
            !status?.activated_stake
          ) {
            localStorage.setItem("onboarding_process", "pending");
            this.router.navigateByUrl('/complete-registeration');
          }
          else if (user?.data?.customer?.email_verified_at !== null) {
            localStorage.setItem("onboarding_process", "completed");
            // this.router.navigate([this.returnUrl]);
          } else {
            localStorage.setItem("onboarding_process", "completed");
            // this.hasError = true;
          }
        }
      },
      error: (err: any) => {
        // console.log(err)
      }
    })
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true

      let onb = localStorage.getItem("onboarding_process");
      if (onb == null) {
        let user = JSON.parse(localStorage.getItem('user') || "{}");
        this.continueProcess(user);
      }
      if (currentUser && onb == 'completed') {
        // not logged in so redirect to login page with the return url
        return true;
      }
      else if (onb == 'pending') {

        location.href = '/complete-registeration';
        return false;
      }
    }
    else {
      let onb = localStorage.getItem("onboarding_process");
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        this.authService.logout();
        return false;
      }
      else if (onb == 'pending') {

        location.href = '/complete-registeration';
        return false;
      }
    }
  }
}
