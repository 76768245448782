<!-- begin::Header-->
<div class="card-header align-items-center border-0 mt-4">
  <h3 class="card-title align-items-start flex-column">
    <span class="fw-bolder mb-2 text-dark">Activities</span>
    <span class="text-muted fw-bold fs-7">890,344 Sales</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu-->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu-->
  </div>
</div>
<!-- end::Header-->
<!-- begin::Body-->
<div class="card-body pt-5">
  <!-- begin::Timeline-->
  <div class="timeline-label">
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">08:42</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-warning fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Text-->
      <div class="fw-mormal timeline-content text-muted ps-3">
        Outlines keep you honest. And keep structure
      </div>
      <!-- end::Text-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">10:00</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-success fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Content-->
      <div class="timeline-content d-flex">
        <span class="fw-bolder text-gray-800 ps-3">AEOL meeting</span>
      </div>
      <!-- end::Content-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">14:37</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-danger fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Desc-->
      <div class="timeline-content fw-bolder text-gray-800 ps-3">
        Make deposit
        <a href="#" class="text-primary">USD 700</a>. to ESL
      </div>
      <!-- end::Desc-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">16:50</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-primary fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Text-->
      <div class="timeline-content fw-mormal text-muted ps-3">
        Indulging in poorly driving and keep structure keep great
      </div>
      <!-- end::Text-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">21:03</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-danger fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Desc-->
      <div class="timeline-content fw-bold text-gray-800 ps-3">
        New order placed
        <a href="#" class="text-primary">#XF-2356</a>.
      </div>
      <!-- end::Desc-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">16:50</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-primary fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Text-->
      <div class="timeline-content fw-mormal text-muted ps-3">
        Indulging in poorly driving and keep structure keep great
      </div>
      <!-- end::Text-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">21:03</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-danger fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Desc-->
      <div class="timeline-content fw-bold text-gray-800 ps-3">
        New order placed
        <a href="#" class="text-primary">#XF-2356</a>.
      </div>
      <!-- end::Desc-->
    </div>
    <!-- end::Item-->
    <!-- begin::Item-->
    <div class="timeline-item">
      <!-- begin::Label-->
      <div class="timeline-label fw-bolder text-gray-800 fs-6">10:30</div>
      <!-- end::Label-->
      <!-- begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-success fs-1"></i>
      </div>
      <!-- end::Badge-->
      <!-- begin::Text-->
      <div class="timeline-content fw-mormal text-muted ps-3">
        Finance KPI Mobile app launch preparion meeting
      </div>
      <!-- end::Text-->
    </div>
    <!-- end::Item-->
  </div>
  <!-- end::Timeline-->
</div>
<!-- end: Card Body-->
