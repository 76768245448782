import { RouterModule, Routes } from "@angular/router";
import { TowersComponent } from "./overview/overview.component";
import { NgModule } from "@angular/core";
import { manage_tower } from '../auth/services/permission.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [manage_tower],
    component: TowersComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TowersRoutingModule { }
