import { TokenService } from './token.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export type UserType = any | undefined;

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;

  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }
  constructor(
    private router: Router,
    private token: TokenService,
    private authHttpService: AuthHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.token.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    if (auth.data == null || auth.data.token === null) {
      // //console.log('no token');
      // this.router.navigateByUrl('auth');
      // this.logout();
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.data.token).pipe(
      map((user: UserType) => {
        if (user) {
          const result = this.token.saveUserToStorage(user);
          return result;
        } else {
          // this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
