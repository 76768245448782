import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export type UserType = any | undefined;

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  // private fields
  private authLocalStorageToken = `user`;
  private userLocalStorageToken = `userdetails`;
  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {}

  // private methods
  public setAuthFromLocalStorage(auth: any): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public saveUserToStorage(data: any): boolean {
    if (data) {
      localStorage.setItem(this.userLocalStorageToken, JSON.stringify(data));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getUserFromStorage() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData.permissions;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getUser() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getAuth() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      const a = authData.data.token;
      return a;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  // Function to check if a user has verified there email
  public checkEmailVerified(): any {
    const userdata: any = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken) || '{}'
    );
    const email_verified = userdata?.data?.customer?.email_verified_at;
    if (email_verified != null) {
      return true;
    } else {
      return false;
    }
  }

  // Function to check if a user has verified there email
  public checkIsCreator(): any {
    const userdata: any = JSON.parse(
      localStorage.getItem(this.userLocalStorageToken) || '{}'
    );
    const coin = userdata?.permission_coin;
    if (coin != null) {
      return true;
    } else {
      return false;
    }
  }
}
