<div
  id="kt_drawer_chat"
  class="bg-body"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'300px', 'md': '500px'}"
  data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_drawer_chat_toggle"
  data-kt-drawer-close="#kt_drawer_chat_close"
>
  <div class="card w-100 rounded-0" id="kt_drawer_chat_messenger">
    <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
      <div class="card-title">
        <div class="d-flex justify-content-center flex-column me-3">
          <a
            class="
              fs-4
              fw-bolder
              text-gray-900 text-hover-primary
              me-1
              mb-2
              lh-1
              cursor-pointer
            "
            >Brian Cox</a
          >
          <div class="mb-0 lh-1">
            <span
              class="badge badge-success badge-circle w-10px h-10px me-1"
            ></span
            ><span class="fs-7 fw-bold text-gray-400">Active</span>
          </div>
        </div>
      </div>
      <div class="card-toolbar">
        <div class="me-2">
          <button
            class="btn btn-sm btn-icon btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <i class="bi bi-three-dots fs-3"></i>
          </button>
        </div>
        <div
          class="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_drawer_chat_close"
        >
          <span class="svg-icon svg-icon-2"></span>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </div>
      </div>
    </div>
    <app-chat-inner [isDrawer]="true"></app-chat-inner>
  </div>
</div>
