import {
  NgbModal,
  NgbModalConfig,
  NgbNavConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Inject,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { PermissionService } from '../../auth/services/permission.service';
import { TokenService } from '../../auth/services/token.service';
import Swal from 'sweetalert2';
import { SegmentsService } from '../../segments/segments.service';
import { TwitterService } from '../../twitter/twitter.service';
moment().format();

@Component({
  selector: 'app-post-airdrop',
  templateUrl: './post-airdrop.component.html',
  styleUrls: ['./post-airdrop.component.scss'],
})
export class PostAirdropComponent implements OnInit {
  dropForm: any = {
    coin: null,
    channel: null,
    amount: null,
    type: null,
    total_claimable: null,
    minimum: 0,
    restrict_type: 'none',
    restrict_value: '',
    restrict_minimum: 0,
    status: null,
    note: null,
    top_reactors_percentage: null,
  };
  disabled = true;
  editDropForm: any = {
    total_claimable: null,
    status: null,
    restrict_type: null,
    restrict_value: null,
    restrict_minimum: null,
  };

  refresh: any;
  roomOn = false;
  active: any = 5;
  space_data: any = {};
  duration: any;
  updated_at: any;
  started_at: number;
  events: any;
  tabledata = 'admins';
  name = '';
  name2 = '';
  name3 = '';
  newAdmins: any;
  newListeners: any;
  newSpeakers: any;
  adminId: any[];
  speakersId: any[];
  date: string;
  message: any;
  results: any;
  bot_id: any;
  selectBot: any;
  key: string = 'date_entered';
  direction = 'asc';
  reverse: boolean = true;
  cointype: any = 'solana_coin';
  droptype: any = 'space';
  allChannel: any;
  allcoins: any;
  seclectedchannel: any;
  rallycoins: any;
  loading: boolean;
  pages: any = 1;
  savedTweets: any;
  sizes: any;
  perpages: any;
  allDrops: any = [];
  drop_id: any;
  sortKey: string = 'minutes';

  direction2 = 'desc';
  reverse2 = false;
  space_id: string;
  tweet_id: any;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  isLoading$: Observable<boolean>;
  private unsubscribe: Subscription[] = [];
  admins: any;
  speakers: any;
  listeners: any;

  key2: string = 'date_entered';
  id: string | null;
  viewDrop: boolean;
  managewDrop: boolean;
  allUsers: any[];
  coinHoders: any[];
  userId: any;
  totalCoins: any;
  coin: any;
  giraffeOwners: any;
  show: string = '';
  showonly: string = '';
  searchUser: boolean;
  displayFilter: boolean;
  showonlyone: boolean;
  showDisplay: string;
  showAllData: boolean;
  allcoins2: any;
  allSegments: any;
  selectedDrop_total: any;
  // toggled: boolean = false;
  toggled: boolean = false;
  //  message: string;
  public textArea: string = '';
  public isEmojiPickerVisible: boolean;
  constructor(
    nconfig: NgbNavConfig,
    private user: TwitterService,
    private router: Router,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private route: ActivatedRoute,
    private token: TokenService,
    private permission: PermissionService,
    private segmentService: SegmentsService
  ) {
    nconfig.destroyOnHide = true;
    nconfig.roles = false;
    config.backdrop = 'static';
    config.keyboard = false;
    this.id = route.snapshot.paramMap.get('id');
    this.dropForm.channel = this.id;
    this.date = moment().format('YYYY-MM-DD');
    this.isLoading$ = this.user.isLoading$;
    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);

    this.permission.getUserByToken().subscribe({
      next: (res) => {
        const a = this.token.getUser();

        this.checkManage(a?.permissions);
      },
    });
  }


  public addEmoji(event) {
    this.textArea = `${this.textArea}${event.emoji.native}`;
    // this.isEmojiPickerVisible = true;
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  checkManage(a: any) {
    const first = a.find((obj: any) => {
      return obj.name === 'view_twitter_drops';
    });
    this.viewDrop = !!first;

    const second = a.find((obj: any) => {
      return obj.name === 'manage_twitter_drops';
    });
    this.managewDrop = !!second;
  }

  ngOnInit(): void {
    const user = this.token.getAuthFromLocalStorage();
    const data = user.data;
    this.userId = data.customer.ch_user_id;
    this.coin = data.customer.permission_coin;
    this.sort(this.key2);
    this.getSpaceData();
    this.getCoins();
    this.getCoinsall()
    this.getTweets();
    this.listAllSegments()
    // this.getPostDrops();
    this.refresh = setInterval(() => {
      this.getSpaceData();
    }, 30000);
  }
  ngOnDestroy(): void {
    if (this.refresh) {
      clearInterval(this.refresh);
    }
  }


  handleSelection(event) {
    this.message += event.char;
    console.log(this.message);
  }
  // handleSelection(event) {
  //   console.log(event.char);
  // }
  getTweets() {
    this.loading = true;
    this.user.getAllSavedTweets().subscribe({
      next: (res: any) => {
        const data = res.data;
        // //console.log(data);
        this.savedTweets = res.data;
      },
      complete: () => {
        this.loading = false;
      },
      error: (err: any) => {
        //console.log(err);
        this.loading = false;
      },
    });
  }

  getCoins() {
    this.user.getSolanaCoins().subscribe({
      next: (res: any) => {
        this.allcoins = res.data;
      },
    });
  }
  sort(key: string) {
    this.key2 = key.toLowerCase();
    this.reverse2 = !this.reverse2;
    if (this.reverse2) {
      this.direction2 = 'desc';
    } else {
      this.direction2 = 'asc';
    }
    this.getPostDrops();
  }
  adddrop(content4: any) {
    this.modalService.open(content4, { size: 'lg' }).result;
  }

  getPostDrops() {
    this.allDrops.length = 0;
    this.user
      .listPostDrops(this.pages, this.key2, this.direction2, this.dropForm.channel)
      .subscribe({
        next: (res: any) => {
          //console.log(res);
          console.log('airdrops', res.data.data);
          const data = res.data;
          const a = res.data.data;
          this.allDrops = res.data.data;
          // for (let i = 0; i < a.length; i++) {
          //   const element = a[i];
          //   if (element.channel === this.id) {
          //     if (this.allDrops.length !== 0) {
          //       this.allDrops.push(element);
          //     } else {
          //       this.allDrops.length = 0;
          //       this.allDrops.push(element);
          //     }
          //   }
          // }
          //console.log(this.allDrops);
          this.sizes = data.total;
          this.perpages = data.per_page;
        },
        complete: () => { },
        error: (err: any) => {
          //console.log(err);
        },
      });
  }

  editDrop(
    content3: any,
    total_claimable: any,
    status: any,
    restrict_type: any,
    restrict_value: any,
    restrict_minimum: any,
    drop_id: any,
    drops_claimed_count: any
  ) {
    this.selectedDrop_total = drops_claimed_count;
    this.editDropForm.total_claimable = total_claimable;
    this.editDropForm.status = status;
    this.editDropForm.restrict_type = restrict_type;
    this.editDropForm.restrict_value = restrict_value;
    this.editDropForm.restrict_minimum = restrict_minimum;
    this.drop_id = drop_id;
    this.modalService.open(content3, { size: 'md' }).result;
  }

  onSubmit2(f: NgForm) {
    // //console.log(f.value);
    const payload = f.value;
    payload.emoji = this.textArea;
    this.user.createPostDrops(payload).subscribe({
      next: (res: any) => {
        Swal.fire('Success', res.message, 'success')
        //console.log(res);
      },
      complete: () => {
        this.getPostDrops();
        this.modalService.dismissAll();
        this.dropForm = {
          coin: null,
          channel: this.id,
          // channel = this.id,
          amount: null,
          type: null,
          total_claimable: null,
          minimum: 0,
          end: null,
          start: null,
          emoji: null,
          restrict_type: 'none',
          restrict_value: '',
          restrict_minimum: 0,
          status: null,
          note: null,
          top_reactors_percentage: null
        }
      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.message, 'error')
        //console.log(err);
      },
    });
  }

  editSubmit2(f: NgForm) {
    //console.log(f.value);
    this.user.editPostDrops(this.drop_id, f.value).subscribe({
      next: (res: any) => {
        Swal.fire('Success', res.message, 'success')
        // this.alert.success(res.message);
      },
      complete: () => {
        this.modalService.dismissAll();
        this.getPostDrops();
      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.message, 'error')
        // this.alert.danger(err.error.message);
      },
    });
  }
  getPages(page: any) {
    this.pages = page;
    this.getPostDrops();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }
  getCoinsall() {
    this.user.getallSolanaCoins().subscribe({
      next: (res: any) => {
        this.allcoins2 = res.data;
      },
    });
  }
  getSpaceData() {
    this.user.getSpacetEvents(this.id).subscribe({
      next: (res: any) => {
        console.log(res);
        this.admins = [];
        this.speakers = [];
        this.listeners = [];
        this.allUsers = [];
        const data = res.data;
        this.space_data = data.space_data;
        this.updated_at = parseFloat(this.space_data?.updated_at);
        this.started_at = parseFloat(this.space_data?.started_at);
        this.duration = this.timeDifference(this.updated_at, this.started_at);
        this.events = res.data.events;
        // //console.log(this.events);
        // this.alert.warning(this.space_data?.state);
        if (this.space_data?.state === 'Running') {
          this.admins = data.admin;
          this.speakers = data.speakers;
          this.listeners = data.listeners;
        } else {
          clearInterval(this.refresh);
          this.adminId = [];
          this.speakersId = [];
          for (let i = 0; i < this.events.length; i++) {
            const element = this.events[i];
            if (element.action == 'admin_space') {
              this.admins.push(element);
              this.adminId.push(element.twitter_user_id);
            } else if (element.action == 'speakers_space') {
              this.speakers.push(element);
              this.speakersId.push(element.twitter_user_id);
            }
          }

          for (let i = 0; i < this.events.length; i++) {
            const element = this.events[i];
            if (
              element.action == 'attend_space' &&
              !this.speakersId.includes(element.twitter_user_id) &&
              !this.adminId.includes(element.twitter_user_id)
            ) {
              this.listeners.push(element);
            }
          }
        }
      },
      complete: () => {
        this.allUsers = [...this.admins, ...this.listeners, ...this.speakers];
        // this.allUsers.push(this.admins, this.speakers, this.listeners);
        // console.log(this.allUsers);
        this.getCoinCount();
        this.getGiraffeOwners();
        this.loading = false;
      },
      error: (err: any) => { },
    });
  }

  getCoinCount() {
    this.coinHoders = [];
    let total = 0;
    for (let i = 0; i < this.allUsers.length; i++) {
      const element = this.allUsers[i];

      if (parseFloat(element.coin_balance) > 0) {
        this.coinHoders.push(element);
        // ////console.log(this.coinHoders);
      }
      // alert(this.userId);

      if (parseInt(element.user_id) !== parseInt(this.userId)) {
        total += parseFloat(element.coin_balance);
      }
    }
    this.totalCoins = total + ' ' + this.coin;
    // console.log(this.coinHoders);
    // console.log(this.totalCoins);
  }
  getGiraffeOwners() {
    // coin_balance
    this.giraffeOwners = [];
    let total = 0;
    for (let i = 0; i < this.allUsers.length; i++) {
      const element = this.allUsers[i];

      if (element.giraffe_balance > 0) {
        this.giraffeOwners.push(element);
      }
    }
    // console.log(this.giraffeOwners);
  }

  showOnly(ev: string) {
    this.show = '';
    this.show = ev;
    this.searchUser = false;
    this.displayFilter = false;
    this.showonlyone = false;

    if (this.show === 'coin') {
      this.displayFilter = true;
      this.showonly = 'coin';
    } else if (this.show === 'giraffe_owner') {
      this.displayFilter = true;
      this.showonly = 'giraffe_owner';
    } else {
      this.clearFilter();
    }
  }

  clearFilter() {
    this.sortKey = '';
    // this.showonlyone = false;
    this.showDisplay = '';
    this.show = '';
    this.showonly = '';
    this.searchUser = false;
    this.showAllData = false;
    this.displayFilter = false;
    this.showonlyone = false;
    this.name2 = '';
    // this.rrverse = null;
    //  this.searchUser = false;
    // ////console.log(this.showonlyone);
  }

  timeDiff(timestamp1: number, timestamp2: number) {
    var difference = timestamp1 - timestamp2;
    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    //console.log(daysDifference);
    return daysDifference;
  }

  timeDifference(date1: number, date2: number) {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    if (daysDifference > 0) {
      difference -= daysDifference * 1000 * 60 * 60 * 24;
    }

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    if (hoursDifference > 0) {
      difference -= hoursDifference * 1000 * 60 * 60;
    }

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    var secondsDifference = Math.floor(difference / 1000);

    return (
      daysDifference +
      ' day(s) ' +
      hoursDifference +
      ' hr(s) ' +
      minutesDifference +
      ' min(s) ' +
      secondsDifference +
      ' sec(s) '
    );
  }

  formatDateTime2(dateString: string | number | Date) {
    const parsed = moment(new Date(dateString));

    if (!parsed.isValid()) {
      return dateString;
    }

    return parsed.format(' HH:mm A');
  }

  formatDateTime(dateString: string | number | Date) {
    const parsed = moment(new Date(dateString));

    if (!parsed.isValid()) {
      return dateString;
    }

    return parsed.format('MMM D, YYYY, HH:mm A');
  }

  convert(value: number) {
    const data: any = moment.duration(value, 'minutes');
    //console.log(data._data);
    return (
      String(data._data.hours).padStart(2, '0') +
      ':' +
      String(data._data.minutes).padStart(2, '0')
    );
  }

  converthr(value: number) {
    const data: any = moment.duration(value, 'days');
    // //console.log(data._data);
    return (
      String(data._data.hours).padStart(2, '0') +
      ':' +
      String(data._data.minutes).padStart(2, '0') +
      ':' +
      String(data._data.seconds).padStart(2, '0')
    );
  }
  startedAT(c: string | number | Date) {
    // const b = parseFloat(c);
    var date = new Date(c);
    return date;
  }

  gotoUser(data: any) { }

  cancel() {
    this.modalService.dismissAll();
  }

  formatServerTimeToUserTimezone(time: any) {
    return moment().utc(time).local().format('DD/MM/YY HH:MM:SS');
  }

  onClick(name: string) {
    this.router.navigateByUrl('/sc/twitter/profile/' + name)
  }
  listAllSegments() {
    this.segmentService.getAllSegments().subscribe({
      next: (res: any) => {
        this.allSegments = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

}
