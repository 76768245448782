<div [ngClass]="footerContainerCssClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between">
  <!-- begin::Copyright  -->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted fw-bold me-2">{{ currentDateStr }} &copy;</span>
    <a class="text-gray-800 text-hover-primary cursor-pointer">
      Social connector. A DigitalMarketing.org Company
    </a>
  </div>
  <!-- end::Copyright  -->

  <!-- begin::Nav  -->
  <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
    <li class="menu-item">
      <a (click)="watchTutorialModal()" class="menu-link ps-0 pe-2 cursor-pointer">
        Watch tutorial
      </a>
    </li>
    <li class="menu-item">

      <a href="https://docs.socialconnector.io/welcome-to-social-connector" target="_blank"
        class="menu-link ps-0 pe-2 cursor-pointer">
        Docs
      </a>
    </li>
    <li class="menu-item">
      <a href="mailto:team@digitalmarketing.org" target="_blank" class="menu-link pe-0 pe-2 cursor-pointer">
        Contact
      </a>
    </li>
    <li class="menu-item">
      <a href="https://discord.gg/YERX2dNAGS" target="_blank" class="menu-link pe-0 pe-2 cursor-pointer">
        Support
      </a>
    </li>
    <li class="menu-item">
      <a href="https://gary.club/welcome" target="_blank" class="menu-link ps-0 pe-2 cursor-pointer">
        Gary.club
      </a>
    </li>
    <li class="menu-item">
      <a href="/fees" class="menu-link pe-0 pe-2 cursor-pointer">
        Fees
      </a>
    </li>
  </ul>
  <!-- end::Nav  -->
</div>