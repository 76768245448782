<ngx-loading-bar
  ref="http"
  color="#f87674"
  height="5px"
  diameter="20px"
></ngx-loading-bar>
<lib-toastify-toast-container [transition]="'slide'" [autoClose]="10000" [newestOnTop]="true"
  [iconLibrary]="'font-awesome'"></lib-toastify-toast-container>
<ngx-loading-bar
  ref="router"
  color="#f87674"
  height="2px"
  diameter="0px"
></ngx-loading-bar>
<!-- <ngx-loading-bar ref="core" height="10px" diameter="50px"></ngx-loading-bar> -->
<router-outlet></router-outlet>
