import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {
  FormBuilder,
  FormGroup,
  Validator,
  Validators,
  FormControl,
} from '@angular/forms';
import { SocialpayService } from 'src/app/modules/social-pay/service/socialpay.service';
import { LayoutService } from 'src/app/_metronic/layout';
import { WalletService } from 'src/app/modules/account/settings/forms/load-giveaway-wallet/wallet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { Transaction } from '@solana/web3.js';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  show = false;
  payment_token: any;
  payment_page_details: any;
  transactions: any;
  paymentPageForm: FormGroup;
  currency: any;
  custom_fields: any = [];
  footerContainerCssClasses: string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  aForm: FormGroup;
  // readonly wallets$ = this.walletservice.wallets$;
  // readonly wallet$ = this.walletservice.wallet$;
  // readonly walletName$ = this.walletservice.walletName$;
  // readonly walletIcon$ = this.walletservice.walletIcon$;
  // readonly ready$ = this.walletservice.ready$;

  // readonly connected$ = this.walletservice.connected$;
  // public publicKey$ = this.walletservice.publicKey$;
  private unsubscribe: Subscription[] = [];
  checkoutForm: FormGroup;
  comp: any = [];
  data: any = {};
  data2: any = {};
  page_link: any;
  total: any;
  initResp: any;
  paybtn: boolean = false;
  generaResp: any;
  respTnx: any;
  signature: any;
  custom: any = [];
  payments_processors: any;
  selectedMethod: any;
  selected_name: any = 'Social Pay';
  color_mode: string;
  queryParams: any;
  disabled: boolean;
  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private socialPayService: SocialpayService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private layout: LayoutService,
    // private walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private loader: LoadingBarService,
    private router: Router,
    private socialpayService: SocialpayService,
    private activatedRoute: ActivatedRoute,
   
  ) {
    const loadingSubscr = this.isLoading$$
    .asObservable()
    .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);
  }


  last_index = 100;

   counter = 100;

   info: any;

  parseQueryParams(queryParams: any): any {
    // Split the query parameters string by '&'
    const queryParamsArray = queryParams.split('&');
    const resultObject = {};

    // Iterate over each key-value pair and split by '='
    for (const param of queryParamsArray) {
      const [key, value] = param.split('=');
      // Decode the URL-encoded value
      resultObject[key] = decodeURIComponent(value);
    }

    return resultObject;
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      // Convert the query parameters to an object
      this.queryParams = queryParams;
    });
    // this.walletservice.initialize();
    this.footerContainerCssClasses =
      this.layout.getStringCSSClasses('footerContainer');
    this.payment_token = this.route.snapshot.paramMap.get('token');
    this.getPaymentPageId(this.payment_token);
    this.checkoutForm = this.fb.group({
      custom_fields: this.fb.group({})
    });
    this.aForm = this.fb.group({});
    const success_or_failed_route = this.activatedRoute?.snapshot?.url[1]?.path;
    if (success_or_failed_route === 'failed') {
      this.failed()
    } else if (success_or_failed_route === 'success') {
      this.success();
    }


    this.last_index = (this.info.substring(0, 100)).lastIndexOf(' ');
    if(this.last_index > 100)
      this.last_index = 100;
      this.counter = this.last_index;

  }

  showTxt = "Show More"


  toggleSkil(e: any){
    if(this.counter < 101 )
      {
        this.counter = this.info.length;
        this.showTxt = "Show less";
      } else {
        this.counter = this.last_index;
        this.showTxt = "Show More"
      }
  }

 firstCount = 100
  showMore() {
    this.show = !this.show;
  }
  setForm() {
    // let form = {};
    // for (let i = 0; i < this.custom.length; i++) {
    //   let element = this.custom[i];
    //   console.log(element)
    //   form[element] = new FormControl('');
    // }
    // this.aForm = new FormGroup(form);

    this.aForm = this.fb.group({});
    for (let i = 0; i < this.custom.length; i++) {
      let element = this.custom[i];
      this.data[element] = ['', Validators.required];
    }
    this.aForm = this.fb.group(this.data);
  }
  initForm() {
    this.checkoutForm = this.fb.group({
      custom_fields: this.fb.group({})
    });
    for (let i = 0; i < this.comp.length; i++) {
      let element = this.comp[i];
      let iii;
      if (this.queryParams.email) {
        this.disabled = true
        if (element === 'email') {
          iii = [this.queryParams.email, Validators.required,];
        } else {
          iii = ['', Validators.required];
        }
        this.data[element] = iii
      }

      else {
        this.disabled = false
        this.data[element] = ['', Validators.required];
      }

    }
    for (let i = 0; i < this.custom.length; i++) {
      let element2 = this.custom[i];
      this.data2[element2] = [''];
    }
    this.data['custom_fields'] = this.fb.group(this.data2)
    this.checkoutForm = this.fb.group(this.data);
    // this.myForm.addControl('newcontrol', []);
    // this.checkoutForm.controls.custom_fields = this.fb.group(this.data2);
  }

  // function to get payment page details
  getPaymentPageId(payment_token: any) {
    this.socialPayService
      .getPaymentsPageDetailsWithLink(payment_token)
      .subscribe({
        next: (res: any) => {
          this.payment_page_details = res?.data;
          this.comp = this.payment_page_details.compulsory_fields;
          this.custom = this.payment_page_details.custom_fields;
          this.page_link = this.payment_page_details.page_link;
          if (this.payment_page_details.fee_bearer === 'client') {
            this.total =
              parseFloat(this.payment_page_details.provider_fee) +
              parseFloat(this.payment_page_details.amount_charge);
            // this.total = this.total.toFixed(5);
          } else {
            this.total = parseFloat(this.payment_page_details.amount_charge);
          }
          this.info = this.payment_page_details?.description;
          this.getPaymentProcessor();
        },
        complete: () => {
          this.initForm();

        },
        error: (err: any) => {
          Swal.fire('Failed to get payment ID', err.error.message, 'error');
        },
      });
  }
  convertToPlainText(html: string): any {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent;
  }

  onSubmit() {
    this.checkoutForm.value.page_link = this.page_link;
    // this.checkoutForm.value.custom_fields = {};
    console.log(this.checkoutForm.value)
    this.socialPayService.initPayment(this.checkoutForm.value).subscribe({
      next: (res: any) => {
        // this.paybtn = true;
        this.initResp = res;
        let tnxref = this.initResp.payment_transaction.transaction_reference;
        this.router.navigateByUrl(`checkout/pay/${tnxref}`);
      },
      error: (err: any) => {
        Swal.fire('Failed!!!', err.error.message, 'error');

        // this.paybtn = false;
      },
    });
  }

  onSubmitStripe() {
    this.checkoutForm.value.page_link = this.page_link;
    // this.checkoutForm.value.custom_fields = {};
    this.socialPayService.initStripePayment(this.checkoutForm.value).subscribe({
      next: (res: any) => {
        // this.paybtn = true;
        this.initResp = res;
        let tnxref = this.initResp.page_link;
        // this.router.navigateByUrl(`checkout/pay/${tnxref}`);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = tnxref;
        a.click();
        document.body.removeChild(a);
        console.log(res);
      },
      error: (err: any) => {
        Swal.fire('Failed!!!', err.error.message, 'error');
        console.log(err);
        // this.paybtn = false;
      },
    });
  }


  strReplace(data: any) {
    return data.replaceAll('_', ' ');
  }

  // Function to select payment processor
  paymentProcessor(event: any, item: any) {
    this.selectedMethod = item;
    this.selected_name = item?.name;
    if (this.selectedMethod?.name === 'Stripe Connect') {
      this.total = this.payment_page_details?.stripe_amount;
    } else {
      if (this.payment_page_details.fee_bearer === 'client') {
        this.total =
          parseFloat(this.payment_page_details.provider_fee) +
          parseFloat(this.payment_page_details.amount_charge);
        // this.total = this.total.toFixed(5);
      } else {
        this.total = parseFloat(this.payment_page_details.amount_charge);
      }
      // this.total = this.payment_page_details?.amount_charge;
    }
  }

  getCommonElements(arr1, arr2) {
    return arr1.filter(obj1 => arr2.some(obj2 => obj2.id === obj1.id && obj2.name === obj1.name));
  }
  //  Get payment processor
  getPaymentProcessor() {
    this.socialpayService.getpayprocessor().subscribe({
      next: (res: any) => {
        const stripeAmount = this.payment_page_details?.stripe_amount;
        if (stripeAmount !== 0) {
          this.payments_processors = res?.data;
        } else if (stripeAmount === 0) {
          const payments = res?.data;
          const result = payments.filter(item => { return item.name === 'Social Pay' });
          this.payments_processors = result;
        }
      }, complete: () => {
        this.color_mode = localStorage.getItem('kt_theme_mode_value');
        if (this.color_mode === null) {
          this.color_mode = 'dark';
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }


  success() {
    Swal.fire('Success', 'Payment successful', 'success');
  }

  failed() {
    Swal.fire('Error', 'Payment failed', 'error');
  }

  returnDayText(e: any) {
    if(e === 'daily') {
      return 'Days'
    }
    if(e === 'monthly') {
      return 'Months'
    }
    if(e === 'yearly') {
      return 'Years'
    }
  }

}
