<!--begin::Tiles Widget 11-->
<div
  class="card card-custom bg-"
  [ngClass]="cssClass"
  [ngStyle]="{ height: widgetHeight }"
>
  <div class="card-body">
    <span
      [inlineSVG]="'./assets/icons/duotune/general/gen025.svg'"
      class="svg-icon svg-icon-3x svg-icon-white ml-n2"
    ></span>

    <div
      [ngClass]="textInverseCSSClass"
      class="font-weight-bolder font-size-h2 mt-3"
    >
      790
    </div>

    <a
      href="#"
      [ngClass]="textInverseCSSClass"
      class="font-weight-bold font-size-lg mt-1"
      >New Products</a
    >
  </div>
</div>
<!--end::Tiles Widget 11-->
