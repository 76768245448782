<div class="row mt-7" *ngIf="(isLoading$ | async) === false">
    <div class="col-12" *ngIf="viewDrop">
        <div class="card">
            <div class="m-5">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3 class="mb-0">My Airdrops</h3>
                    </div>
                    <div class="col-md-6 text-end" *ngIf="managewDrop">
                        <button class="btn btn-primary" (click)="adddrop(content4)">
                            Create a Drop
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table align-middle">
                    <thead class="thead-default">
                        <tr class="fw-bolder text-muted bg-light">
                            <!-- <th (click)="sort('channel')">Channel &nbsp; <i class="fa fa-sort"></i></th> -->
                            <th class="ps-5 py-5">Coin</th>
                            <th class="py-5">Drop Type</th>
                            <th class="py-5">Amount</th>

                            <th class="py-5">
                                Restriction Amount
                            </th>
                            <th class="py-5">
                                Restriction Type
                            </th>
                            <th class="py-5">
                                Restriction Value
                            </th>
                            <th class="py-5">Minimum</th>
                            <th class="py-5">Total Claimable</th>
                            <th class="py-5">Total Claimed</th>
                            <th class="py-5">Date</th>
                            <th class="py-5">Status</th>
                            <th class="py-5" *ngIf="managewDrop">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of allDrops | orderBy: key2:reverse2">
                            <td class="ps-5">{{ item.coin }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.amount }}</td>
                            <td>{{ item.restrict_minimum}}</td>
                            <td>{{ item.restrict_type}}</td>
                            <td>{{ item.restrict_value}}</td>
                            <td>{{ item.minimum }}</td>
                            <td>{{ item.total_claimable }}</td>
                            <td>{{ item.drops_claimed_count }}</td>
                            <td>{{ item.date_entered_custom }}</td>
                            <td>{{ item.status }}</td>
                            <td class="d-flex" *ngIf="managewDrop">
                                <a class="btn btn-clear btn-sm text-info" placement="top"
                                    [ngbTooltip]="'View Claimed drops '" [routerLink]="['claimed-drop/' + item.drop_id]"
                                    routerLinkActive="router-link-active">
                                    <i class="fa-solid fa-eye"></i>
                                </a>
                                <button *ngIf="item.status !== 'expired'" class="btn btn-clear btn-sm text-warning"
                                    placement="top" [ngbTooltip]="'Edit Drop'" (click)="
                      editDrop(
                        content2,
                        item.total_claimable,
                        item.status,
                        item.restrict_type,
                        item.restrict_value,
                        item.restrict_minimum,
                        item.drop_id,
                        item.drops_claimed_count
                      )
                    ">
                                    <i class="fa-solid fa-pen"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <ngb-pagination class="d-flex justify-content-center mt-3" [pageSize]="perpages" [collectionSize]="sizes"
            [(page)]="pages" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
            (pageChange)="getPages($event)"></ngb-pagination> -->
            </div>
        </div>
    </div>
</div>

<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Drop</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <!-- <strong>x</strong>   -->
        </button>
    </div>

    <div class="modal-body my-3">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <form name="form" (ngSubmit)="f.form.valid && editSubmit2(f)" #f="ngForm" novalidate>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label for="">Status</label>
                                <select class="form-select py-3" [(ngModel)]="editDropForm.status" name="status"
                                    required>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                        </div>


                        <div class="text-end">
                            <!-- <button class="btn btn-active-light" (click)="cancel()">
                  Cancel
                </button> -->
                            <button class="btn btn-primary ms-5" [disabled]="f.form.invalid">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #content4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create a drop</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <!-- <strong>x</strong>   -->
        </button>
    </div>

    <div class="modal-body my-3">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <form name="form" (ngSubmit)="f.form.valid && onSubmit2(f)" #f="ngForm" novalidate>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label for="">Coin</label>
                                <select name="coin" [(ngModel)]="dropForm.coin" #coin="ngModel" class="form-select"
                                    required>
                                    <option value="$GARY">
                                        $GARY
                                    </option>
                                </select>
                                <!-- <input
                    class="form-control"
                    name="coin"
                    list="datalistOptions"
                    id="exampleDataList2"
                    [(ngModel)]="dropForm.coin"
                    #coin="ngModel"
                    required
                    placeholder="Type to search..."
                  />
                  <datalist id="datalistOptions">
                    <option
                      *ngFor="let item of allcoins"
                      [value]="item.coin_symbol"
                    ></option>
                  </datalist> -->
                            </div>

                            <div class="col-md-6">
                                <label for="">Enter Post Link</label>
                                <input type="text" class="form-control" name="channel" [(ngModel)]="dropForm.channel"
                                    #channel="ngModel" required />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label for="">Amount</label>
                                <input type="text" class="form-control" [(ngModel)]="dropForm.amount" name="amount"
                                    required />
                            </div>
                            <div class="col-md-6">
                                <label for="">Type</label>
                                <select class="form-select py-3" name="type" id="" [(ngModel)]="dropForm.type" required>
                                    <option value="">Select Type</option>
                                    <option value="like">Like</option>
                                    <option value="comment">Comment</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label for="">Total Claimable</label>
                                <input type="number" class="form-control" name="total_claimable"
                                    [(ngModel)]="dropForm.total_claimable" required />
                            </div>
                            <div class="col-md-6">
                                <label for="">Minimum</label>
                                <input type="number" class="form-control" name="minimum" [(ngModel)]="dropForm.minimum"
                                    [disabled]="dropForm.type !== 'minutes_listened'" />
                            </div>
                        </div>

                        <div
                            *ngIf="dropForm.type === 'emoji_drop' || dropForm.type === 'emoji_drop_random' || dropForm.type === 'top_reactors' ">
                            <div class="row mb-3">
                                <label for="">Emoji</label>
                                <div class="input-group mb-3">

                                    <div class="dropdown dropdown-inline show">
                                        <button class="btn btn-hover-light-primary border rounded-0 border-end-0"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                            (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">Select
                                            Emoji</button>
                                        <!-- <br> -->
                                        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right show"
                                            x-placement="bottom-end" style="position: absolute; ">
                                            <!-- transform; top: 0px; left: 0px; transform: translate3d(-217px, -344px, 0px); -->
                                            <!-- <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible"
                                                (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart> -->
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" type="text" name="textarea"
                                        [(ngModel)]="textArea">
                                </div>

                                <!-- <div class="input-group has-validation">
                    <div class="dropdown dropdown-inline show">
                      <button class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true"
                        (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</button>
                      <div class="dropdown-menu dropdown-menu-md dropdown-menu-right show" x-placement="bottom-end"
                        style="position: absolute; ">
                        <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
                          title="Choose your emoji"></emoji-mart>
                      </div>
                    </div>
                    <div class="form-floating is-invalid">
                      <input type="text" class="form-control" placeholder="Pick an emoji" type="text" name="textarea"
                        [(ngModel)]="textArea">
                      <label for="floatingInputGroup2">Pick an Emoji</label>
                    </div>
                  </div> -->
                                <!-- <textarea class="form-control" placeholder="Pick an emoji" type="text" name="textarea"
                    [(ngModel)]="textArea"></textarea> -->
                                <!-- <div class="dropdown dropdown-inline show">
                    <button class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="true"
                      (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</button>
                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-right show" x-placement="bottom-end"
                      style="position: absolute; ">
                      <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
                        title="Choose your emoji"></emoji-mart>
                    </div>
                  </div> -->
                            </div>
                            <!-- <div  *ngIf="dropForm.type === 'top_reactors' " class="row mb-3">
                  <label for="">Top Reactor Percentage</label>
                  <input type="text" name="top_reactors_percentage" class="form-control" value=""
                    [(ngModel)]="dropForm.top_reactors_percentage" />
                </div> -->
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="">Start</label>
                                    <input type="number" class="form-control" name="start"
                                        [(ngModel)]="dropForm.start" />
                                </div>
                                <div class="col-md-6">
                                    <label for="">End</label>
                                    <input type="number" class="form-control" name="end" [(ngModel)]="dropForm.end" />
                                </div>
                            </div>

                        </div>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label for="">Restrict Type</label>
                                <select class="form-select py-3" name="restrict_type" id=""
                                    [(ngModel)]="dropForm.restrict_type" required>
                                    <option value="none">None</option>
                                    <option value="solana_coin">Solana Coin</option>
                                    <!-- <option value="eth_nft">ETH NFT</option> -->
                                    <option value="segment">Segment</option>
                                </select>
                            </div>

                            <div class="col-md-6">
                                <div *ngIf="dropForm.restrict_type === 'none'">
                                    <label for="">Restrict Value</label>
                                    <input type="text" name="restrict_value" class="form-control" disabled value="0" />
                                </div>
                                <div *ngIf="dropForm.restrict_type === 'solana_coin'">
                                    <label for="">Select Coin</label>

                                    <select name="restrict_value" [(ngModel)]="dropForm.restrict_value"
                                        #restrict_value="ngModel" class="form-select" required>
                                        <option value="$GARY">
                                            $GARY
                                        </option>
                                    </select>

                                    <!-- <input
                      class="form-control"
                      name="restrict_value"
                      list="datalistOptions"
                      id="exampleDataList2"
                      [(ngModel)]="dropForm.restrict_value"
                      #restrict_value="ngModel"
                      required
                      placeholder="Type to search..."
                    />
                    <datalist id="datalistOptions">
                      <option
                        *ngFor="let item of allcoins"
                        [value]="item.coin_symbol"
                      ></option>
                    </datalist> -->
                                </div>

                                <div *ngIf="dropForm.restrict_type === 'eth_nft'">
                                    <label for="">Enter contract Address</label>
                                    <input type="text" class="form-control" name="restrict_value"
                                        [(ngModel)]="dropForm.restrict_value" #restrict_value="ngModel" required />
                                </div>
                                <div *ngIf="dropForm.restrict_type === 'segment'">
                                    <label for="">Select segment</label>
                                    <select name="restrict_value" [(ngModel)]="dropForm.restrict_value"
                                        #restrict_value="ngModel" required class="form-select">
                                        <option *ngFor="let item of allSegments" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div *ngIf="dropForm.restrict_type === 'none'">
                                    <label for="">Restrict Quantity</label>
                                    <input type="text" class="form-control" readonly disabled value="0"
                                        name="restrict_minimum" />
                                    <!-- <input type="text" name="restrict_value" class="form-control" readonly value="0"
                      /> -->
                                </div>

                                <div *ngIf="dropForm.restrict_type === 'solana_coin'">
                                    <label for="">Restrict Quantity</label>
                                    <input type="number" class="form-control" name="restrict_minimum"
                                        [(ngModel)]="dropForm.restrict_minimum" required />
                                </div>

                                <div *ngIf="dropForm.restrict_type === 'eth_nft'">
                                    <label for="">Restrict Quantity</label>
                                    <input type="number" class="form-control" name="restrict_minimum"
                                        [(ngModel)]="dropForm.restrict_minimum" required />
                                </div>
                                <div *ngIf="dropForm.restrict_type === 'segment'">
                                    <label for="">Restrict Quantity</label>
                                    <input type="number" class="form-control" name="restrict_minimum" value="1"
                                        [(ngModel)]="dropForm.restrict_minimum" required />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label for="">Status</label>
                                <select class="form-select py-3" [(ngModel)]="dropForm.status" name="status" required>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label for="">Note</label>
                                <textarea class="form-control" [(ngModel)]="dropForm.note" name="note" cols="30"
                                    rows="10"></textarea>
                            </div>
                        </div>

                        <div class="text-end">
                            <!-- <button class="btn btn-active-light" (click)="cancel()">
                  Cancel
                </button> -->
                            <button class="btn btn-primary ms-5" type="submit" [disabled]="f.form.invalid">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>