<!--begin::Tiles Widget 13-->
<div
  class="card card-custom bgi-no-repeat"
  [ngClass]="cssClass"
  [ngStyle]="{ height: widgetHeight }"
  style="
    background-color: #663259;
    background-position: calc(100% + 0.5rem) 100%;
    background-size: 100% auto;
    background-image: url('./assets/media/svg/misc/taieri.svg');
  "
>
  <!--begin::Body-->
  <div class="card-body d-flex align-items-center">
    <div>
      <h3 class="text-white font-weight-bolder line-height-lg mb-5">
        Create SaaS<br />Based Reports
      </h3>
      <a href="#" class="btn btn-success font-weight-bold px-6 py-3"
        >Create Report</a
      >
    </div>
  </div>
  <!--end::Body-->
</div>
<!--end::Tiles Widget 13-->
