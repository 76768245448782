import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
const api = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private unsubscribe: Subscription[] = [];
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) { }
  getAllSegments() {
    return this.http.get(`${api}/user/segments`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getAllSegments2(page, payload) {
    if (payload !== '') {
      return this.http.get(`${api}/user/all-user-segments-paginated?page=${page}&search=${payload}`, httpOptions)
        .pipe(finalize(() => this.isLoadingSubject.next(false)));
    } else {
      return this.http.get(`${api}/user/all-user-segments-paginated?page=${page}`, httpOptions)
        .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }
  }
  CreateSegments(data: any) {
    return this.http.post(`${api}/user/segments`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getAllSegmentsFilter(id: any) {
    return this.http.get(`${api}/user/segments-filter/${id}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getAllSegmentsFilterType() {
    return this.http.get(`${api}/user/segments-filterTypes`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  CreateSegmentsFIlter(data: any) {
    return this.http.post(`${api}/user/segments/add-filter`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  deleteSegments(id: any) {
    return this.http.delete(`${api}/user/segment/${id}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  // getAllSegmentsFilterEntries(id: any, page: any) {
  //   return this.http.get(`${api}/user/segment-filter-entries/${id}?page=${page}`, httpOptions)
  //     .pipe(finalize(() => this.isLoadingSubject.next(false)));
  // }
  getAllSegmentsFilterEntries(id: any, value: any, page: any) {
    return this.http.get(`${api}/user/segment-filter-entries/${id}?search=${value}&page=${page}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  deleteSegmentsFilter(segmentId: any, filterId: any) {
    return this.http.delete(`${api}/user/segment-filter-group/${segmentId}/${filterId}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getSolanaCoins() {

    return this.http.get(`${api}/solana/coin/role`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  refreshSegment(id: any) {
    return this.http.get(`${api}/user/segments/refresh/${id}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getContracts() {
    return this.http.get(`${api}/solana/fetch-contracts-wp`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getContractTraits(data: any) {
    return this.http.get(`${api}/ethereum/fetch-contract-traits/${data}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  download(id) {
    return this.http.get(`${api}/user/segment-snapshot-data/${id}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  updateSegment(data, id) {
    return this.http.post(`${api}/user/segments/update-name/${id}`, data, httpOptions)
  }

  addUserToSegment(id, data) {
    return this.http.post(`${api}/user/add-user-segment/${id}`, data, httpOptions)
  }

  removeUserFromSegment(id, data) {
    return this.http.post(`${api}/user/remove-user-segment/${id}`, data, httpOptions)
  }

  createSegmentDrops(data) {
    return this.http.post(`${api}/user/segments/drops/store`, data, httpOptions).pipe()
  }

  updateSegmentDrops(data, id) {
    return this.http.patch(`${api}/user/segments/drops/update/${id}`, data, httpOptions).pipe()
  }
  listSegmentDrops(page) {
    return this.http.get(`${api}/user/segments/drops?page=${page}`, httpOptions).pipe()
  }

  viewClaimedDrops(id, page) {
    return this.http.get(`${api}/user/segments/drops/claimed/${id}?page=${page}`, httpOptions).pipe()
  }

  getRecuringPaymentConfig() {
    return this.http.get(`${api}/social-pay/recurring-payment/get-my-payments`, httpOptions).pipe()
  }


  getSupporterCoins() {

    return this.http.get(`${api}/solana/user/stake/role`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


}

// https://gatewaytesting.socialconnector.io/solana/user/stake/role
// /social-pay/recurring-payment/get-my-payments
