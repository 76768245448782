import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslationService } from '../../../../../../modules/i18n';
import { AuthService, UserType } from '../../../../../../modules/auth';
import { SettingsService } from 'src/app/modules/account/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})
export class UserInnerComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  // language: LanguageFlag;
  user$: Observable<UserType>;
  // langs = languages;
  private unsubscribe: Subscription[] = [];
  team: any = [];

  constructor(
    private auth: AuthService,
    private translationService: TranslationService,
    private settings: SettingsService,
    private router: Router
  ) {
    this.user$ = this.settings.currentUserSubject.asObservable();
  }

  ngOnInit(): void {
    this.getTeams();
    // this.setLanguage(this.translationService.getSelectedLanguage());
    // //console.log(this.user$);
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }
  getTeams() {
    // team_id: 653
    // team_name: "jonathan solagbade
    this.settings.getMyTeam().subscribe({
      next: (res: any) => {
        this.team = res.data;
        // //console.log(res);
      },
    });
  }
  switchTeam(team_id: any) {
    this.settings.switchTeam(team_id).subscribe({
      next: (res) => {
        // //console.log(res);
      },
      complete: () => {
        this.router.navigateByUrl('/dashboard');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
    });
  }

  // selectLanguage(lang: string) {
  //   this.translationService.setLanguage(lang);
  //   this.setLanguage(lang);
  //   // document.location.reload();
  // }

  // setLanguage(lang: string) {
  //   this.langs.forEach((language: LanguageFlag) => {
  //     if (language.lang === lang) {
  //       language.active = true;
  //       this.language = language;
  //     } else {
  //       language.active = false;
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

// interface LanguageFlag {
//   lang: string;
//   name: string;
//   flag: string;
//   active?: boolean;
// }

// const languages = [
//   {
//     lang: 'en',
//     name: 'English',
//     flag: './assets/media/flags/united-states.svg',
//   },
//   {
//     lang: 'zh',
//     name: 'Mandarin',
//     flag: './assets/media/flags/china.svg',
//   },
//   {
//     lang: 'es',
//     name: 'Spanish',
//     flag: './assets/media/flags/spain.svg',
//   },
//   {
//     lang: 'ja',
//     name: 'Japanese',
//     flag: './assets/media/flags/japan.svg',
//   },
//   {
//     lang: 'de',
//     name: 'German',
//     flag: './assets/media/flags/germany.svg',
//   },
//   {
//     lang: 'fr',
//     name: 'French',
//     flag: './assets/media/flags/france.svg',
//   },
// ];
