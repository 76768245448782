import { TokenService } from './../auth/services/token.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
// import { UserModels } from './models/user.model';
import { Router } from '@angular/router';
const api = `${environment.apiUrl}`;

export type UserType = any | undefined;

// export type UserType = UserModels | undefined;

// const userUrl = environment.userUrl;
// const url = 'https://monitor.clubhousetools.xyz/api';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class TowerService implements OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
//   user: UserModels;
  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  // section for contracts
  getContracts() {
    return this.http.get(`${api}/solana/fetch-contracts-wp`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  getContractTraits(data: any) {
    return this.http.get(`${api}/ethereum/fetch-contract-traits/${data}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getTraittypeandValue() {
    return this.http.get(`${api}/exchange/get-trait-type-and-value`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getAllSegmentsFilterType() {
    return this.http.get(`${api}/user/segments-filterTypes`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // section for towers
  getAllTowers(page: any, key: any, direction: any) {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/exchange/tower/my-tower?page=${page}&sort_key=${key}&direction=${direction}`).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  createTower(data: any): Observable<any> {
    this.isLoadingSubject.next(true);

    const apiUrl = `${api}/exchange/tower/`;

    return this.http.post(apiUrl, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateTower(id: any, data: any): Observable<any> {
    this.isLoadingSubject.next(true);

    const apiUrl = `${api}/exchange/tower/${id}`;

    return this.http.put(apiUrl, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  deleteTower(id: any, data: any): Observable<any> {
    this.isLoadingSubject.next(true);

    const apiUrl = `${api}/exchange/tower/${id}`;

    return this.http.delete(apiUrl, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}

